import React, {  useEffect } from "react";
import "../App.css";
import Axios from "axios";
import "bootstrap/dist/css/bootstrap.css";
import {
  Card,
  Button,
} from "react-bootstrap";
import { useHistory } from "react-router";
import housing from "../images/housing.jpg";
import employment from "../images/employment.jpg";
import mentalHealth from "../images/mentalHealth.jpg";
import substanceUse from "../images/substanceUse.jpg";
import CommonNav from "../common/CommonNav";
import { useGlobalConfigContext } from "../App";

function MyPlans() {
  const serverDomain   = useGlobalConfigContext()["serverDomain"];
  const history = useHistory();
  Axios.defaults.withCredentials = true;

  const houseFunc = () => {
    history.push("/housingPlan");
    window.location.reload();
  };
  const employFunc = () => {
    history.push("/employmentPlan");
    window.location.reload();
  };
  const mentalHealthFunc = () => {
    history.push("/mentalHealthPlan");
    window.location.reload();
  };
  const substanceUseFunc = () => {
    history.push("/substanceUsePlan");
    window.location.reload();
  };

  useEffect(() => {
    document.title = "My Plans";  
    Axios.get(serverDomain+"/login").then((response) => {
      if (response.data.loggedIn === true) {
      } else {
        history.push("/login");
      }
    });
  }, []);

  return (
    <div className="container home">
      <CommonNav />
      <div className="row justify-content-center">
        <div className="">
          <Card style={{ cursor: "pointer" }} onClick="">
            <Card.Body>
              <Card.Title className="cardTitleCustom">My Plans</Card.Title>
            </Card.Body>
          </Card>
        </div>
      </div>
      <div className="row justify-content-center MyPlanCustom">
        <div className="col-4">
          <Card style={{ cursor: "pointer" }} onClick={houseFunc}>
            <Card.Img
              style={{ width: "100%", height: "10rem" }}
              variant="top"
              src={housing}
            />
            <Card.Body>
              <Card.Title>Housing Plan</Card.Title>
              <Button className="fullWidth" variant="primary">
                Continue
              </Button>
            </Card.Body>
          </Card>
        </div>
        <div className="col-4">
          <Card style={{ cursor: "pointer" }} onClick={employFunc}>
            <Card.Img
              style={{ width: "100%", height: "10rem" }}
              variant="top"
              src={employment}
            />
            <Card.Body>
              <Card.Title>Employment Plan</Card.Title>
              <Button className="fullWidth" variant="primary">
                Continue
              </Button>
            </Card.Body>
          </Card>
        </div>
      </div>

      <div className="row justify-content-center MyPlanCustom">
        <div className="col-4">
          <Card style={{ cursor: "pointer" }} onClick={mentalHealthFunc}>
            <Card.Img
              style={{ width: "100%", height: "10rem" }}
              variant="top"
              src={mentalHealth}
            />
            <Card.Body>
              <Card.Title>Mental Health Organizations</Card.Title>
              <Button className="fullWidth" variant="primary">
                Continue
              </Button>
            </Card.Body>
          </Card>
        </div>
        <div className="col-4">
          <Card style={{ cursor: "pointer" }} onClick={substanceUseFunc}>
            <Card.Img
              style={{ width: "100%", height: "10rem" }}
              variant="top"
              src={substanceUse}
            />
            <Card.Body>
              <Card.Title>Substance Use Services</Card.Title>
              <Button className="fullWidth" variant="primary">
                Continue
              </Button>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default MyPlans;
