import React, {  } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Citation from "./Citation";

function ApplyingJobs(props) {
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h3>Finding Potential Employment in the Future</h3> 
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Applying to Jobs</h4>
          <p>
            <u>Apply to 5-10 jobs per week!</u> The more job applications you
            submit, the more opportunities you have for gaining employment. Here are
            some helpful tips:
            <ul>
              <li>
                Read each job posting carefully to determine how you should apply.
                Some have online applications and others want you to email them your
                resume.
              </li>
              <li>
                Go to places of employment in person. This is a great opportunity to
                make a good first impression and allows employers to put a face with
                your resume. Even if they are not currently hiring, many employers
                will put your resume on file. When a job opens up, they will be more
                likely to remember you if they have met you already!
              </li>
            </ul>
            <u>Follow-up on your job applications!</u>
          </p>
          <p>
            One week after sending in your application, call and ask about the
            status of your application.
            <ul>
              <li>
                Don’t get discouraged if you don’t hear from a potential employer
                right away! Business owners can be very busy and it may take a few
                weeks to go through all of the applications they receive.
              </li>
              <li>
                Following-up on your application is a <b>GREAT</b> way to show
                employers that you are serious about the position.
              </li>
            </ul>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Citation/>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ApplyingJobs;
