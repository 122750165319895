import React, { } from "react";
import Pagination from "react-bootstrap/Pagination"

function PaginationBar({selectedPage, pageCount, firstClickPaging, preClickPaging, nextClickPaging, lastClickPaging}) {

    return (
        <>
            <Pagination>
                {selectedPage <= 1 ? (
                <Pagination.First disabled />
                ) : (
                <Pagination.First onClick={() => firstClickPaging()} />
                )}

                {selectedPage <= 1 ? (
                <Pagination.Prev disabled />
                ) : (
                <Pagination.Prev onClick={() => preClickPaging()} />
                )}
                <Pagination.Item>
                page {selectedPage} of {pageCount}
                </Pagination.Item>

                {selectedPage >= pageCount ? (
                <Pagination.Next disabled />
                ) : (
                <Pagination.Next onClick={() => nextClickPaging()} />
                )}

                {selectedPage >= pageCount ? (
                <Pagination.Last disabled />
                ) : (
                <Pagination.Last onClick={() => lastClickPaging()} />
                )}
            </Pagination>
        </>
    );
}
export default PaginationBar