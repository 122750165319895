import React, { } from "react";
import Axios from "axios";
import {
  Nav,
  Navbar,
  NavDropdown,
  Button
} from "react-bootstrap";
import { useHistory } from "react-router";
import { useGlobalConfigContext } from "../App";
function CommonNav() {
  const serverDomain   = useGlobalConfigContext()["serverDomain"];
  const history = useHistory();

  const logoutUser = () => {
    Axios.post( serverDomain+"/logout", {}, { withCredentials: "true" });
    history.push("/login");
  };

  return (
    <Navbar
      className="navbar-custom"
      variant="dark"
      fixed="top"
      expand="lg"
      collapseOnSelect
    >
      <Navbar.Brand>
        <Button href="/home" variant="outline-info">Reentry and Corrections </Button>
      </Navbar.Brand>
      <Navbar.Toggle />
      <Navbar.Collapse>
        <Nav>
          <Nav.Link href="/home">Home</Nav.Link>
          <NavDropdown title="Organizations" id="navbarScrollingDropdown">
            <NavDropdown.Item href="housing">
              Housing Organizations
            </NavDropdown.Item>
            <NavDropdown.Item href="/employment">
              Employment Organizations
            </NavDropdown.Item>
            <NavDropdown.Item href="/basicNeeds">Basic Needs</NavDropdown.Item>
            <NavDropdown.Item href="/health">
              Health Organizations
            </NavDropdown.Item>
            <NavDropdown.Item href="/hotline">
              Hotline Services
            </NavDropdown.Item>
            <NavDropdown.Item href="/information">
              Social Service Information
            </NavDropdown.Item>
            <NavDropdown.Item href="/mentalHealth">
              Mental Health Organizations
            </NavDropdown.Item>
            <NavDropdown.Item href="/substanceUse">
              Substance Use Services
            </NavDropdown.Item>
            <NavDropdown.Item href="/universal">
              Universal Services
            </NavDropdown.Item>
          </NavDropdown>
          <Nav.Link href="/favorites">Favorites</Nav.Link>
          <Nav.Link href="/profile">Profile</Nav.Link>
          <Nav.Link href="/myPlans">My Plans</Nav.Link>
          <Nav.Link onClick={logoutUser}> Logout </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default CommonNav;
