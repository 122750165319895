import React, { } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Citation from "./Citation";

function RelaxationTechniques(props) {
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           <h3>Finding Potential Mental Health Services</h3> 
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <p><b><u>Relaxation Techniques</u></b></p>
          <p>There are several ways that may be helpful for you to relax, calm down, or destress 
            during difficult situations. This section will highlight a few for you! Find your 
            favorites and use them to just take a moment to breathe when you need to.</p>
          <p><b><u>Do what makes you happy today</u></b></p>
          <p>Everyone has bad days. It may help to do something you enjoy such as reading a book, 
            playing sports, or going to the movies. Think of something that you enjoy that is 
            positive and won’t get you in trouble later. Write down what you are going to do to 
            make yourself happy today!</p>
          <p><b><u>Spend 10 minutes in the sunshine</u></b></p>
          <p>Research has found that being in the sun, even for a brief period, can improve our mood, 
            promote vitamin D production, and improve sleep quality. Take ten minutes out of your day 
            just to relax and enjoy the benefits the sun has to offer!</p>
          <p><b><u>Exercise</u></b></p>
          <p>Exercise and physical activity are a great way to feel better and have fun with added 
            health benefits. Just aiming to move around more for 30 minutes a day can make a big 
            difference! 
              Exercise combats health conditions and diseases
              Exercise improves mood
              Exercise boosts energy
              Exercise promotes better sleep
              Set a personal goal to exercise this week! How many 
              minutes and how many days of the week?</p>
        </Modal.Body>
        <Modal.Footer>
          <Citation/>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default RelaxationTechniques;
