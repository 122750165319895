
function Citation(props) {
  return (
    <>
      <cite>Smith, L., Lerch, J., & Taxman, F. S. (2022). The Center for Advancing Correctional Excellence (ACE!), Arlington, VA. &nbsp;
        yours.gmuace.org
      </cite>      
    </>
  );
}

export default Citation;
