import React, {  } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Citation from "./Citation";


function MaintainWorkEthic(props) {
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" >
          <h3>Finding Potential Employment in the Future</h3> 
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Maintain a Proper Work Ethic!</h4>
          <p>
            A work ethic is a set of moral principles an employee uses in his/her
            job. <b>These are five factors that indicate a strong work ethic:</b>
            <ul>
              <li>
                <u>Integrity</u>
                <ul>
                  <li>
                    Reward yourself! When you are honest with yourself about
                    something that you did wrong, that is personal growth!
                  </li>
                  <li>
                    Check in with yourself! Do a scan of your own behaviors and
                    actions regularly to see how you are doing. Examine yourself and
                    your efforts.
                  </li>
                  <li>
                    Reinforce others! Sometimes other people will let you know
                    something you did, or are about to do, is not right. Thank these
                    people sincerely. They are giving you the potential for personal
                    growth!
                  </li>
                </ul>
              </li>

              <li>
                <u>Sense of Responsibility</u>
                <ul>
                  <li>
                    A strong sense of responsibility affects how an employee works.
                    The more responsible an employee feels for their job
                    performance, the more effort and time they will put into making
                    sure they are doing a good job.
                  </li>
                </ul>
              </li>

              <li>
                <u>Emphasis on Quality</u> Employees with a strong work ethic care
                about their work. They do their best to produce the best quality of
                work that they can.
                <ul>
                  <li>
                    Don’t just do the bare minimum to get by. You may be completing
                    your work but putting in the effort shows that you care about
                    the work you are doing.
                  </li>
                  <li>
                    Producing the best quality can help improve your company’s
                    overall quality! This can make you feel good about yourself, and
                    your employer will appreciate the effort!
                  </li>
                </ul>
              </li>

              <li>
                <u>Discipline</u> Discipline helps you stay focused on your goals
                and tasks needed to complete assignments.
                <ul>
                  <li>
                    Remove temptations! Sometimes a messy workspace may cause you to
                    be distracted. Tidy up a little so you can focus on your work!
                  </li>
                  <li>
                    Schedule breaks, treats, and rewards for yourself! If you can’t
                    take a break during work, bring a treat for yourself or a little
                    reward like a piece of chocolate. Take a second for you and then
                    get back to work!
                  </li>
                  <li>
                    Forgive yourself and move forward! Sometimes we slip up. We are
                    late to work, we are unable to finish a project, or other
                    instances come up. Learn from this experience! See what you did
                    or what you should have done and move forward. We are all humans
                    and are constantly learning and growing!
                  </li>
                </ul>
              </li>

              <li>
                <u>Sense of Teamwork</u>
                <ul>
                  <li>
                    Many jobs require working together in teams. Building trust and
                    respect between team members is important for quality work. You
                    don’t have to be best friends with your team, but remember to
                    treat them with respect and they’ll treat you with respect. This
                    will make work go much smoother and help keep you focused on the
                    tasks!
                  </li>
                </ul>
              </li>
            </ul>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Citation/>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default MaintainWorkEthic;
