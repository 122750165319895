import React, {} from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Citation from "./Citation";

function FindingHelp(props) {
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
             <h3>Finding Potential Substance Use Information</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p><b><u>Finding Help</u></b> </p>
          <p>Dealing with a drug or alcohol problem is not easy. Many people find it hard to stop 
              using substances without the help of others. Support groups and drug/alcohol treatment 
              is a great way to learn good behaviors and how to avoid harmful ones. Here are some ways 
              you can find help for your substance use.</p>
            <p>
            <ul>
                <li>
                  <u>Find a counselor or treatment program in your area</u> Deciding to go to a treatment 
                  counselor or program is a big step and you should be proud of yourself. Use your 
                  favorited organizations on the left to find more information for treatment programs 
                  that are right for you. Once you find a few, you can call and make an appointment for 
                  your release. 
                </li>
                <li>
                  <u>Contact helpful resources.</u> The National Clearinghouse for Alcohol and Drug 
                  Information is an organization that provides both English and Spanish speaking 
                  specialists who can recommend appropriate publications for you to read and refer 
                  you to organizations that will fit your need. They are available 24 hours a day, 
                  7 days a week at: 1-800-729-6686 for English and 1-877-767-8432 for Spanish. Contact 
                  the National Alcoholism and Substance Abuse Information Center to talk with someone 
                  about getting help and to find treatment centers in your area. You can call them 
                  at: 1-800-784-6776 (24 hours a day, 7 days a week).
                </li>
              </ul>
            </p>
        </Modal.Body>
        <Modal.Footer>
          <Citation/>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default FindingHelp;
