import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookmark as farB } from "@fortawesome/free-regular-svg-icons";

function MessageNoFavorite(props) {
  return (
    <>
      <div className="disp1">
        <p className="favorite-org-message">                 
        <FontAwesomeIcon icon={farB} /> Opp! You do not have any favorites for this organization yet
        </p>
        <p className="favorite-org-message"> Please go to organization to add it to your favorite list</p>
      </div>
    </>
  );
}

export default MessageNoFavorite;
