import React, { } from "react";
import classNames from "classnames";
import { Button } from "react-bootstrap";
function HealthKeywordButtons({photoIDChecked,handleFilterHealthPhotoIdKeyWord,
                              substanceUseChecked,handleFilterHealthSubstanceUseKeyWord,
                              residentVirginiaChecked,handleFilterHealthResidentVirginiaKeyWord,
                              pregnantChecked,handleFilterHealthPregnantKeyWord
                              }) 
{
    return (
        <>
            <Button
              bsPrefix="super-btn"
              value={photoIDChecked}
              onClick={(e) => {
                handleFilterHealthPhotoIdKeyWord();
              }}
              className={classNames({
                keyWords: true,
                btnFilterCustom: photoIDChecked
              })}
            >
              Photo ID 
            </Button>
            <Button
                bsPrefix="super-btn"
                value={substanceUseChecked}
                onClick={(e) => {
                  handleFilterHealthSubstanceUseKeyWord();
                }}
                className={classNames({
                  keyWords: true,
                  btnFilterCustom: substanceUseChecked
                })}
              >
                Substance use 
              </Button>
              <Button
                bsPrefix="super-btn"
                value={residentVirginiaChecked}
                onClick={(e) => {
                  handleFilterHealthResidentVirginiaKeyWord();
                }}
                className={classNames({
                  keyWords: true,
                  btnFilterCustom: residentVirginiaChecked
                })}
              >
                Resident of Virginia  
              </Button>
              <Button
                bsPrefix="super-btn"
                value={pregnantChecked}
                onClick={(e) => {
                  handleFilterHealthPregnantKeyWord();
                }}
                className={classNames({
                  keyWords: true,
                  btnFilterCustom: pregnantChecked
                })}
              >
                 Pregnant 
              </Button>
        </>
        );

}

export default HealthKeywordButtons