import React, {} from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Citation from "./Citation";

function RelaxationTechniqueToTry(props) {
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           <h3>Finding Potential Mental Health Services</h3> 
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <p><b><u>What’s a relaxation technique you want to try? </u></b></p>
            <ul>
              <li>
                <u>Start the Conversation</u>
                <p>Before you decide what to do, you first need to have an honest conversation
                   about what route may be the best for your situation. The only way to truly get 
                   the help you need is to be honest. In order to start the conversation, 
                   you’ll need to ask some very important questions first. See below!</p>
                <ul>
                  <li>
                    <u>Ask yourself:</u>
                    <p>
                    Are my medical and/or mental health problems making my daily life hard to live?
                    Do I need medical and/or mental health help from a professional?
                    Do I have the appropriate health insurance to cover those expenses?
                    What treatment options are right for me to get the help I need?
                    </p>
                  </li>
                  <li>
                    <u>Ask others (e.g., family, friends):</u>
                    <p>
                    Have you noticed any significant changes in my health and/or attitude recently?
                    Do you think I should get help for medical and/or mental health reasons?
                    Where do I go to try to find the help that I need?
                    </p>
                  </li>
                  <li>
                    <u>Ask your doctors:</u>
                    <p>
                    Should I seek additional treatment for my medical and/or mental health issues?
                    What are my treatment options for my medical and/or mental health issues?
                    Which treatment option do you recommend for my medical and/or mental health issues?
                    How long can I expect treatment to take for me to see significant improvement?
                    How much does each treatment option cost? Does insurance typically cover it?
                    </p>
                  </li>
                  <li>
                    <u>Ask your insurance provider:</u>
                    <p>
                    What treatment options are covered under my current insurance plan for my medical 
                    and/or mental health issues?
                    How much would I have to pay out of pocket for each treatment option?
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <u>Talking it Out</u>
                <p>It is very hard to talk about our feelings. By talking about our emotions, 
                  it allows us to work through them in healthier ways. Here are some steps you 
                  can take to start sharing your feelings with others.</p>
              </li>
              <li>
                <u>Find a counselor/therapist in your area</u> to talk with and make an appointment 
                this week. Finding a counselor/therapist that will meet your needs takes time and 
                patience, but you want to find the right one for you! 
              </li>
              <li>
                <u>Active Listening</u>
                <p>Active listening can help you understand another’s point of view and help control 
                  your emotions.
                  It is challenging to actively pay attention when you are having an 
                  intense argument or conversation with someone, but if you can master this skill, 
                  you may find yourself getting less upset during conversations and difficult 
                  situations.
                  Some tips for active listening are to look at the person who is talking, 
                  stay quiet and listen to everything they have to say. When they are done, 
                  take your turn and talk in a calm voice while they listen to you.
                  Practice active listening with a friend or family member today.</p>
              </li>
              <li>
                <u>Anger Management</u>
                <p>If you find your anger is having a negative effect on your life, consider going 
                  to anger management classes.These classes can teach you solutions to deal with 
                  situations and people more positively.</p>
              </li>
              <li>
                <u>Control Techniques</u>
                <p>Test different ways to control your anger and pick those that work best for you. 
                  There are many techniques that experts suggest when you are trying to control your 
                  emotions. Here are a few examples:
                  Find a group of people with a positive outlook and surround yourself with them.
                   It can be easy to get sucked into negativity if you are only around negative people.
                  Change your environment. If you are having an argument inside, take a moment 
                  to breathe deeply and change your location to another room or even outside.
                  Take a time out. Take a few moments to sit down in a quiet spot and cool down. 
                  Take some deep breaths and try to relax yourself. You may find that after 
                  5 minutes, your level of anger has decreased and you are ready to go back 
                  into a situation with a cooler head.
                  </p>
              </li>
              <li>
                <u>Things I will do to control my anger:</u>
                <p>You have just read some ways to control your anger. Reflect on these ideas and 
                  think of your own ways. Write them out on a spare piece of paper and look back to 
                  this section if you ever find yourself struggling to control your emotions.
                </p>
              </li>
              <li>
                <u>Attending Appointments</u>
                <p>In order to be successful in treating your mental health diagnosis, you have 
                  to ensure that you are making appointments, keeping track of appointments, and 
                  attending your appointments. The following set of tips, tasks, and tools will help 
                  you do just that!
                </p>
              </li>
              <li>
                <u>Making Appointments</u>
                <p>Scheduling appointments may seem scary at first if it’s something you have never 
                  done before. Don’t worry! Now, it’s easier than ever. Once you have decided on a 
                  doctor’s office, treatment facility, or hospital, then scheduling is a breeze. 
                  Use the following tips to help you!
                  Ensure the treatment provider takes your insurance company and plan before scheduling.
                  Browse the treatment provider’s website for online scheduling options.
                  Be sure to have your calendar ready so that you can mark your appointment date for 
                  when it’s scheduled!
                  If you can’t schedule an appointment online, call your treatment provider and ask 
                  for the next available appointment date/time.
                  Ask any questions you may have about the service being provided upfront so that 
                  there are no surprises on the day of your appointment.
                  If you know you’ll have to return for several treatments, schedule 
                  those in advance too.
                </p>
              </li>
              <li>
                <u>Tracking Appointments</u>
                <p>Keeping track of your appointments doesn’t have to be daunting if you have 
                  more than one. Just don’t forget to use your calendar! You can also use the chart 
                  below to keep more detailed track of what you have coming up next, with whom, 
                  and what for.
                </p>
              </li>
              <li>
                <u>Attending Appointments</u>
                <p>Once you schedule an appointment, it is crucial that you attend the appointment! 
                  Keep in mind that some providers will charge you if you do not show-up or you do 
                  not cancel in a timely manner. Also, you deserve to get the treatment you need. 
                  That being said, it is important that you consider whether the treatment you 
                  receive is putting you on the right track to becoming healthy.
                </p>
              </li>
            </ul>
        </Modal.Body>
        <Modal.Footer>
          <Citation/>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default RelaxationTechniqueToTry;
