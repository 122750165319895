import React, { } from "react";
import classNames from "classnames";
import { Button } from "react-bootstrap";
function HousingKeywordButtons({menChecked,handleFilterHousingMenKeyWord,
                             womenChecked,handleFilterHousingWomenKeyWord,
                             familiesChecked,handleFilterHousingFamiliesKeyWord,
                             socialSercurityChecked,handleFilterHousingSocialSercurityKeyWord,
                             plus60Checked,handleFilterHousingPlus60KeyWord,
                             substanceFreeChecked,handleFilterHousingSubstanceFreeKeyWord,
                             hivPositiveChecked,handleFilterHousingHIVPositiveKeyWord,
                             survivorDomisticViolenceChecked,handleFilterHousingSurvivorDomisticViolenceKeyWord
                              }) 
{
    return (
        <>
            <Button 
              bsPrefix="super-btn"
              value={menChecked}
              onClick={(e) => {
                handleFilterHousingMenKeyWord();
              }}
              className={classNames({
                keyWords: true,
                btnFilterCustom: menChecked
              })}
            >
              Men
            </Button>
            <Button
                bsPrefix="super-btn"
                value={womenChecked}
                onClick={(e) => {
                  handleFilterHousingWomenKeyWord();
                }}
                className={classNames({
                  keyWords: true,
                  btnFilterCustom: womenChecked
                })}
              >
                Women
              </Button>
              <Button
                bsPrefix="super-btn"
                value={familiesChecked}
                onClick={(e) => {
                  handleFilterHousingFamiliesKeyWord();
                }}
                className={classNames({
                  keyWords: true,
                  btnFilterCustom: familiesChecked
                })}
              >
                Families 
              </Button>
              <Button
                bsPrefix="super-btn"
                value={socialSercurityChecked}
                onClick={(e) => {
                  handleFilterHousingSocialSercurityKeyWord();
                }}
                className={classNames({
                  keyWords: true,
                  btnFilterCustom: socialSercurityChecked
                })}
              >
                Social Security  
              </Button>
              <Button
                bsPrefix="super-btn"
                value={plus60Checked}
                onClick={(e) => {
                  handleFilterHousingPlus60KeyWord();
                }}
                className={classNames({
                  keyWords: true,
                  btnFilterCustom: plus60Checked
                })}
              >
                60+ 
              </Button>
              <Button
                bsPrefix="super-btn"
                value={substanceFreeChecked}
                onClick={(e) => {
                  handleFilterHousingSubstanceFreeKeyWord();
                }}
                className={classNames({
                  keyWords: true,
                  btnFilterCustom: substanceFreeChecked
                })}
              >
                Substance free 
              </Button>
              <Button
                bsPrefix="super-btn"
                value={hivPositiveChecked}
                onClick={(e) => {
                  handleFilterHousingHIVPositiveKeyWord();
                }}
                className={classNames({
                  keyWords: true,
                  btnFilterCustom: hivPositiveChecked
                })}
              >
                HIV positive 
              </Button>

              <Button
                bsPrefix="super-btn"
                value={survivorDomisticViolenceChecked}
                onClick={(e) => {
                  handleFilterHousingSurvivorDomisticViolenceKeyWord();
                }}
                className={classNames({
                  keyWords: true,
                  btnFilterCustom: survivorDomisticViolenceChecked
                })}
              >
                Survivor of Domestic Violence 
              </Button>
        </>
        );

}

export default HousingKeywordButtons