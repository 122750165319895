import React, { } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Citation from "./Citation";

function CreateResume(props) {
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           <h3>Finding Potential Employment in the Future</h3> 
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Creating a Resume</h4>
          <p>
            <ul>
              <li>
                <u>What is a resume?</u> A document that outlines your skills,
                experiences and accomplishments. This allows potential employers to
                see if you are a good fit for the job.
              </li>
              <li>
                <u>What does a resume look like?</u>
                Resumes do not all look alike, but here are some general guidelines
                that you should follow to make sure your resume looks professional.
                This includes printing on white paper with black text and using
                standard fonts such as Times New Roman or Arial.
              </li>
              <li>
                <u>What goes on a resume?</u> Your name and contact information,
                Your objective explaining what type of position you are looking for
                and your employment goals OR a summary statement that describes a
                short description of your qualifications for the job opening. Work
                experience, Education, Skills, Volunteer and other experience
              </li>
              <li>
                <u>What if I don’t have work experience?</u> Don’t worry! Just
                because you have not had a job in the past does not mean you do not
                have experience. Experience can come from many different places,
                such as recreational activities and volunteering, as well as work
                within the home.
              </li>
              <li>
                <u>What if I already have a resume?</u>
                Great! You are one step closer to applying for a job. Make sure your
                resume is up to date with your most recent employment, contact
                information, and references. Proofread your resume and make sure
                grammar and spelling is correct. It is always helpful to have
                someone else read your resume and give you some feedback!
              </li>
              <li>
                <u>Any other resume tips?</u>
                When providing contact information, give your full name, phone
                number, and use a professional email. Make sure your resume is easy
                to read. Use a size 10 or 12 font with standard fonts such as Times
                New Roman or Arial. Keep your resume plain and simple. Use only
                black text no multiple colors. Divide the sections or headings of
                your resume with larger text, sized 14 or 16. This will make it
                clear where each section (such as employment, education, skills)
                begins and ends. Information in each section should be in
                chronological order, with the most recent information first, all the
                way down to the oldest information. This will show how you have
                grown and progressed over time
              </li>
            </ul>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Citation/>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CreateResume;
