import React, { } from "react";
import classNames from "classnames";
import { Button } from "react-bootstrap";
function SubstanceKeywordButtons({substanceUseChecked,handleFilterSubstanceUseSubstanceUseKeyWord,
                                  mentalIllnessChecked,handleFilterSubstanceUseMentalIllnessKeyWord,
                                  medicaidChecked,handleFilterSubstanceUseMedicaidKeyWord,
                                  medicareChecked,handleFilterSubstanceUseMedicareKeyWord
                              }) 
{
    return (
        <>
          <Button
              bsPrefix="super-btn"
              value={substanceUseChecked}
              onClick={(e) => {
                handleFilterSubstanceUseSubstanceUseKeyWord();
              }}
              className={classNames({
                keyWords: true,
                btnFilterCustom: substanceUseChecked
              })}
            >
              Substance use 
            </Button>
            <Button
            bsPrefix="super-btn"
            value={mentalIllnessChecked}
            onClick={(e) => {
              handleFilterSubstanceUseMentalIllnessKeyWord();
            }}
            className={classNames({
              keyWords: true,
              btnFilterCustom: mentalIllnessChecked
            })}
          >
            Mental illness 
          </Button>
            <Button
              bsPrefix="super-btn"
              value={medicaidChecked}
              onClick={(e) => {
                handleFilterSubstanceUseMedicaidKeyWord();
              }}
              className={classNames({
                keyWords: true,
                btnFilterCustom: medicaidChecked
              })}
            >
              Medicaid
            </Button>
            <Button
              bsPrefix="super-btn"
              value={medicareChecked}
              onClick={(e) => {
                handleFilterSubstanceUseMedicareKeyWord();
              }}
              className={classNames({
                keyWords: true,
                btnFilterCustom: medicareChecked
              })}
            >
               Medicare 
            </Button>
        </>
        );

}

export default SubstanceKeywordButtons