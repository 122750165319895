import React, { useState, useEffect } from "react";
import "../App.css";
import Axios from "axios";
import "bootstrap/dist/css/bootstrap.css";
import {
  Card,
  Button,
} from "react-bootstrap";
import { useHistory } from "react-router";
import classNames from "classnames";
import CommonNav from "../common/CommonNav";
import { useGlobalConfigContext } from "../App";
import Pagination from "react-bootstrap/Pagination";
import MaintainWorkEthic from "../static/MaintainWorkEthic";
import CreateResume from "../static/CreateResume";
import ApplyingJobs from "../static/ApplyingJobs";
import AddressingCriminalHistory from "../static/AddressingCriminalHistory";
import MaintainingEmployment from "../static/MaintainingEmployment";
import PrepareJobInterview from "../static/PrepareJobInterview";
import OrganizationDetailPopup from "../static/OrganizationDetailPopup";
import MessageNoFavorite from "../static/MessageNoFavorite";

function EmploymentPlan() {
  const serverDomain = useGlobalConfigContext()["serverDomain"];
  const history = useHistory();
  const [unit_org, setUnitOrgData] = useState([]);
  const [active, setActive] = useState(false);

  Axios.defaults.withCredentials = true;
  // const for paging
  const [offset, setOffset] = useState(0);
  const [orgData, setOrgData] = useState([]);
  const [perPage] = useState(3);
  const [pageCount, setPageCount] = useState(0);
  const [selectedPage, setselectedPage] = useState(1);
  const [totalOrg, setTotalOrg] = useState(0);
  const nextClickPaging = (e) => {
    setOffset(offset + perPage);
    setselectedPage(selectedPage + 1);
  };
  const lastClickPaging = (e) => {
    if (totalOrg % perPage === 0) {
      setOffset(totalOrg - perPage);
    } else {
      setOffset(totalOrg - (totalOrg % perPage));
    }
    setselectedPage(pageCount);
  };

  const preClickPaging = (e) => {
    setOffset(offset - perPage);
    setselectedPage(selectedPage - 1);
  };
  const firstClickPaging = (e) => {
    setOffset(0);
    setselectedPage(1);
  };
  // end const for paging

  const [modalShow, setModalShow] = React.useState(false);
  const [flagModalShow, setFlagModalShow] = useState("");
  const [orgModalShow, setOrgModalShow] = useState(false);
  const handleShowInforDetail = (event) => {
    setFlagModalShow(event.currentTarget.id);
    setModalShow(true);
  };

  const getOrganizations = (id) => {
    Axios.get(serverDomain + "/favorites/" + id).then((response) => {
      setActive(id);
      setUnitOrgData(response.data[0]);
      setOrgModalShow(true);
    });
  };

  const checkActive = (id) => {
    return id === active;
  };

  useEffect(() => {
    document.title = "Employment Plan";
    Axios.get(serverDomain + "/login").then((response) => {
      if (response.data.loggedIn === true) {
        Axios.get(serverDomain + "/favorites/employmentPlanAll/").then(
          (response) => {
            console.log("size " + response.data.length);
            setTotalOrg(response.data.length);
            setPageCount(Math.ceil(response.data.length / perPage));
          }
        );
        Axios.post(serverDomain + "/favorites/employmentPlanPaging/", {
          offset: offset,
          perPage: perPage,
        }).then((response) => {
          setOrgData(response.data);
          console.log(response.data);
        });
      } else {
        history.push("/login");
      }
    });
  }, [offset]);
  useEffect(() => {
    Axios.post( serverDomain+"/login/insertLoginTrackerByIdAndPageName", {
      page_name: "employmentPlan",
    }).then(function(response) {
    })
    .catch(function(error) {
      console.log(error);
    })
  }, [])
  return (
    <div className="container home">
      <CommonNav />
      <div className="row justify-content-center">
        <div className="">
          <Card style={{ cursor: "pointer" }} onClick="">
            <Card.Body>
              <Card.Title className="cardTitleCustom">
                Employment Plan
              </Card.Title>
            </Card.Body>
          </Card>
        </div>
      </div>
        <div className="homeDisplay overflow-hidden">
          {totalOrg > 0 ? (
          <div className="disp1">
            <h5>Total Organizations: {totalOrg}</h5>
            {orgData.map(function(org) {
              return (
                <div
                  onClick={() => getOrganizations(org.org_id)}
                  className={classNames({
                    ldisp: true,
                    active: checkActive(org.org_id),
                  })}
                >
                  <div className="ttl">{org.name}</div>
                  <div className="subSecond">{org.address}</div>
                  <div className="subttl">{org.type}</div>
                </div>
              );
            })}
            <div className="pagingWrap">
              <Pagination>
                {selectedPage <= 1 ? (
                  <Pagination.First disabled />
                ) : (
                  <Pagination.First onClick={() => firstClickPaging()} />
                )}

                {selectedPage <= 1 ? (
                  <Pagination.Prev disabled />
                ) : (
                  <Pagination.Prev onClick={() => preClickPaging()} />
                )}
                <Pagination.Item>
                  page {selectedPage} of {pageCount}
                </Pagination.Item>

                {selectedPage >= pageCount ? (
                  <Pagination.Next disabled />
                ) : (
                  <Pagination.Next onClick={() => nextClickPaging()} />
                )}

                {selectedPage >= pageCount ? (
                  <Pagination.Last disabled />
                ) : (
                  <Pagination.Last onClick={() => lastClickPaging()} />
                )}
              </Pagination>
            </div>
          </div>
          ) : (
            <MessageNoFavorite/>
          )}
          <div className="disp2">
            <h3 className="textCenter">Finding Potential Employment in the Future</h3>
            <div className="row">
              <div className="col">
                <Card
                  id="workEthic"
                  style={{ cursor: "pointer" }}
                  onClick={handleShowInforDetail}
                >
                  <Card.Body>
                    <Card.Title>Maintain a Proper Work Ethic!</Card.Title>
                    <Card.Text>
                      A work ethic is a set of moral principles an employee uses
                      in his/her job. These are five factors that indicate a
                      strong work ethic
                    </Card.Text>
                    <Button className="fullWidth" variant="primary">
                      See More
                    </Button>
                  </Card.Body>
                </Card>
              </div>
              <div className="col">
                <Card
                  id="createResume"
                  style={{ cursor: "pointer" }}
                  onClick={handleShowInforDetail}
                >
                  <Card.Body>
                    <Card.Title>Creating a Resume</Card.Title>
                    <Card.Text>
                    What is a resume? <br/>
                    What does a resume look like? 
                    <br/>
                    <br/>
                    </Card.Text>
                    <Button className="fullWidth" variant="primary">
                     See More
                    </Button>
                  </Card.Body>
                </Card>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <Card
                  id="applyJobs"
                  style={{ cursor: "pointer" }}
                  onClick={handleShowInforDetail}
                >
                  <Card.Body>
                    <Card.Title >Applying to Jobs</Card.Title>
                    <Card.Text>
                    Apply to 5-10 jobs per week! The more job applications you submit,
                    the more opportunities you have for gaining employment.
                    Here are some helpful tips
                    </Card.Text>
                    <Button className="fullWidth" variant="primary">
                      See More
                    </Button>
                  </Card.Body>
                </Card>
              </div>
              <div className="col">
                <Card
                  id="criminalHistory"
                  style={{ cursor: "pointer" }}
                  onClick={handleShowInforDetail}
                >
                  <Card.Body>
                    <Card.Title>Incarceration/Criminal history</Card.Title>
                    <Card.Text>
                    It is important to make your resume as effective as possible so that you can get a job interview and 
                    the job you are applying for.
                    </Card.Text>
                    <Button className="fullWidth" variant="primary">
                     See More
                    </Button>
                  </Card.Body>
                </Card>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <Card
                  id="prepareJobInterview"
                  style={{ cursor: "pointer" }}
                  onClick={handleShowInforDetail}
                >
                  <Card.Body>
                    <Card.Title >Preparing for a Job interview</Card.Title>
                    <Card.Text>
                    You got a job interview...what’s next? <br/>
                    Congratulate yourself! You should be very proud.
                    <br/>  <br/>
                    </Card.Text>
                    <Button className="fullWidth" variant="primary">
                      See More
                    </Button>
                  </Card.Body>
                </Card>
              </div>
              <div className="col">
                <Card
                  id="maintainingEmployment"
                  style={{ cursor: "pointer" }}
                  onClick={handleShowInforDetail}
                >
                  <Card.Body>
                    <Card.Title>Maintaining Employment</Card.Title>
                    <Card.Text>
                    Tips for Maintaining Employment
                    <br/><br/><br/> 
                    </Card.Text>
                    <Button className="fullWidth" variant="primary">
                     See More
                    </Button>
                  </Card.Body>
                </Card>
              </div>
            </div>
            <div className="row">
              {flagModalShow === "workEthic"
                &&
                <MaintainWorkEthic
                show={modalShow}
                onHide={() => setModalShow(false)}
                />
              }

              {flagModalShow === "createResume"
                &&
                <CreateResume
                show={modalShow}
                onHide={() => setModalShow(false)}
                />
              }

              {flagModalShow === "applyJobs"
                &&
                <ApplyingJobs
                show={modalShow}
                onHide={() => setModalShow(false)}
                />
              }

              {flagModalShow === "criminalHistory"
                &&
                <AddressingCriminalHistory
                show={modalShow}
                onHide={() => setModalShow(false)}
                />
              }
              
              {flagModalShow === "prepareJobInterview"
                &&
                <PrepareJobInterview
                show={modalShow}
                onHide={() => setModalShow(false)}
                />
              }

              {flagModalShow === "maintainingEmployment"
                &&
                <MaintainingEmployment
                show={modalShow}
                onHide={() => setModalShow(false)}
                />
              }
              {unit_org
                &&
                <OrganizationDetailPopup
                show={orgModalShow}
                onHide={() => setOrgModalShow(false)}
                unit_org ={unit_org}
                />
              }
            </div>
          </div>
        </div>
      <div></div>
    </div>
  );
}

export default EmploymentPlan;
