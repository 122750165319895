import React, {} from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Citation from "./Citation";

function AdditionalResourcesFindHelp(props) {
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
             <h3>Finding Potential Substance Use Information</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <p><b><u>Additional Resources to Find Help</u></b> </p>
        <p>There are several other resources that can help you work through your substance use 
            problems. Take a closer look at the following resources and indicate which ones you 
            went to learn more about.</p>
          <p>
          <ul>
              <li>
                <u>12-Step Programs.</u>  If you are looking for help with a specific substance, 
                check out the following information on 12-step programs. This program involves a 
                set of guiding principles (the 12 steps) meant to guide you on your path to recovery. 
                They are not for everyone, but if you are interested, here are some resources to 
                look into further. 
                <p><b>Use the list below to check out 12-step Programs:</b>
                <ul>
                  <li>Alcoholics Anonymous </li>
                  <li>Narcotics Anonymous</li>
                  <li>Cocaine Anonymous</li>
                  <li>Methamphetamine Anonymous</li>
                  <li>Marijuana Anonymous</li>
                  <li>Celebrate Recovery</li>
                  <li>Reformers Unanimous</li>
                  <li>Co-Anon Family Groups for family and friends of cocaine addicts</li>
                  <li>Families Anonymous for friends/family of drug or alcohol users</li>
                  <li>Pills Anonymous</li>
                </ul>
                </p>
              </li>
              <li>
                <u>Other Support Services.</u>   If you are not interested in a recovery program 
                that is spiritual or faith-based, there are still many options for you. Check out 
                the following programs to see which would be the best fit for you.
                <p><b>Use the list below to check out the websites on other resources:</b>
                <ul>
                  <li>SMART Recovery</li>
                  <li>Rational Recovery</li>
                  <li>Moderation Management</li>
                  <li>Harm Reduction, Moderation, and Abstinence Support</li>
                  <li>LifeRing</li>
                  <li>Women for Sobriety</li>
                </ul>
                </p>
              </li>
            </ul>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Citation/>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AdditionalResourcesFindHelp;
