import React, { } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function OrganizationDetailPopup(props) {
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h3>{props.unit_org.name}</h3> 
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div>
            <div className="section">
              <div className="secttl">Organization</div>
              <div className="secsubttl">{props.unit_org.name}</div>
            </div>
            <div className="section">
              <div className="secttl">Address</div>
              <div className="secsubttl">{props.unit_org.address}</div>
            </div>
            <div className="section">
              <div className="secttl">Zip Code</div>
              <div className="secsubttl">{props.unit_org.zip_code}</div>
            </div>
            <div className="section">
              <div className="secttl">Type</div>
              <div className="secsubttl">{props.unit_org.type}</div>
            </div>
            <div className="section">
              <div className="secttl">Description</div>
              <div className="secsubttl">{props.unit_org.description}</div>
            </div>
            <div className="section">
              <div className="secttl">Services</div>
              <div className="secsubttl">{props.unit_org.services}</div>
            </div>
            <div className="section">
              <div className="secttl">Criteria</div>
              <div className="secsubttl">{props.unit_org.criteria}</div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default OrganizationDetailPopup;
