import React, { } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Citation from "./Citation";

function GroundingTechniques(props) {
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           <h3>Finding Potential Mental Health Services</h3> 
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <p><b><u>Grounding Techniques</u></b></p>
          <p>After trauma, it’s normal to experience flashbacks, anxiety, and 
            other uncomfortable symptoms. However, grounding techniques may be helpful 
            control these symptoms by turning your attention away from your thoughts, 
            memories, or worries, and refocusing on the present moment. Try out one of the four 
            techniques below, check off the one you tried, and reflect on how it made you feel 
            afterwards. </p>
          <p>
            <ul>
              <li>
                <u>5-4-3-2-1 Technique:</u>
                <p>Using this technique, you will purposefully take in the details of your surroundings 
                  using each of your senses. Notice the small details that your mind would usually 
                  ignore, such as distant sounds or the texture of an ordinary object. What are 5 
                  things you can see? Look for small details such as a pattern on the ceiling, the way 
                  light reflects off a surface, or an object you never noticed. What are 4 things you 
                  can feel? Notice the sensation of clothing on your body, the sun on your skin, or 
                  the feeling of the chair you are sitting in. Pick up an object and examine its 
                  weight, texture, and other physical qualities. What are 3 things you can hear? 
                  Pay special attention to the sounds your mind has turned out, such as a ticking 
                  clock, distant traffic, or trees blowing in the wind. What are 2 things you can 
                  smell? Try to notice smells in the air around you, like an air freshener or freshly 
                  mowed grass. You may also look around for something that has a scent, such as a 
                  flower or an unlit candle. What is 1 thing you can taste? Carry gum, candy, 
                  or small snacks for this step. Pop one in your mouth and focus your attention 
                  closely on the flavors. </p>
              </li>
              <li>
                <u>Naming Categories:</u>
                <p>Choose at least three of the categories below and name as many items as you can in 
                  each one. Spend a few minutes on each category to come up with as 
                  many items as possible. (Movies,Countries,Books,Cars,TV Shows,Cereals,Cities,
                  Food,Restaurants,Celebrities,Athletes,Animals)</p>
              </li>
              <li>
                <u>Body Awareness:</u>
                <p>The body awareness technique will bring you into the here-and-now by 
                  directing your focus to sensations in the body. Pay special attention to the 
                  physical sensations created by each step. Take 5 long, deep breaths through 
                  your nose, and exhale through puckered lips.Place both feet flat on the floor. 
                  Wiggle your toes. Curl and uncurl your toes several times. Spend a moment 
                  noticing the sensations in your feet. Stomp your feet on the ground several 
                  times. Pay attention to the sensations in your feet and legs as you make contact 
                  with the ground. Clench your hands into fists, then release the tension. 
                  Repeat this 10 times. Press your palms together. Press them harder and hold 
                  this pose for 15 seconds. Pay attention to the feeling of tension in your 
                  hands and arms. Rub your palms together briskly. Notice and sound and the 
                  feeling of warmth. Reach your hands over your head like you’re trying 
                  to reach the sky. Stretch like this for 5 seconds. Bring your arms down and 
                  let them relax at your sides. Take 5 more deep breaths and notice the 
                  feeling of calm in your body.</p>
              </li>
              <li>
                <u>Mental Exercises:</u>
                <p>Use mental exercises to take your mind off uncomfortable thoughts and 
                  feelings. They are discreet and easy to use at nearly any time or place. 
                  Experiment to see which work best for you.
                  Name every object that you can see. 
                  Explain the steps of doing an activity. 
                  Count backwards from 100 by 5. 
                  Describe an object in detail. 
                  Spell your full name backwards. 
                  Name all your family members. 
                  Draw an object in your mind. 
                  List all your family’s ages in order. </p>
              </li>
            </ul>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Citation/>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default GroundingTechniques;
