import React, { } from "react";
import classNames from "classnames";
import { Button } from "react-bootstrap";
function MentalHealthKeywordButtons({medicaidChecked,handleFilterMentalMedicaidKeyWord,
                            medicareChecked,handleFilterMentalMedicareKeyWord,
                            seriousMentalIllnessChecked,handleFilterMentalSeriousMentalIllnessdKeyWord,
                            chronicIllnessChecked,handleFilterMentalChronicIllnessKeyWord,
                            traumaChecked,handleFilterMentalTraumaKeyWord,
                            survivorDomisticViolenceChecked,handleFilterMentalSurvivorDomisticViolenceKeyWord,
                            
                            homelessChecked,handleFilterMentalHomelessKeyWord,
                            deafHearingImpairedChecked,handleFilterMentalDeafHearingImpairedKeyWord,
                            
                            intellectualDisabilityChecked,handleFilterMentalIntellectualDisabilityKeyWord,
                            returningCitizensChecked,handleFilterMentalReturningCitizensKeyWord
                              }) 
{
    return (
        <>
            <Button
              bsPrefix="super-btn"
              value={medicaidChecked}
              onClick={(e) => {
                handleFilterMentalMedicaidKeyWord();
              }}
              className={classNames({
                keyWords: true,
                btnFilterCustom: medicaidChecked
              })}
            >
              Medicaid
            </Button>
            <Button
                bsPrefix="super-btn"
                value={medicareChecked}
                onClick={(e) => {
                  handleFilterMentalMedicareKeyWord();
                }}
                className={classNames({
                  keyWords: true,
                  btnFilterCustom: medicareChecked
                })}
              >
                Medicare
              </Button>
              <Button
                bsPrefix="super-btn"
                value={seriousMentalIllnessChecked}
                onClick={(e) => {
                  handleFilterMentalSeriousMentalIllnessdKeyWord();
                }}
                className={classNames({
                  keyWords: true,
                  btnFilterCustom: seriousMentalIllnessChecked
                })}
              >
                Serious Mental Illness 
              </Button>
              <Button
                bsPrefix="super-btn"
                value={chronicIllnessChecked}
                onClick={(e) => {
                  handleFilterMentalChronicIllnessKeyWord();
                }}
                className={classNames({
                  keyWords: true,
                  btnFilterCustom: chronicIllnessChecked
                })}
              >
                Chronic Illness 
              </Button>
              <Button
                bsPrefix="super-btn"
                value={traumaChecked}
                onClick={(e) => {
                  handleFilterMentalTraumaKeyWord();
                }}
                className={classNames({
                  keyWords: true,
                  btnFilterCustom: traumaChecked
                })}
              >
                Trauma
              </Button>
              <Button
                bsPrefix="super-btn"
                value={survivorDomisticViolenceChecked}
                onClick={(e) => {
                  handleFilterMentalSurvivorDomisticViolenceKeyWord();
                }}
                className={classNames({
                  keyWords: true,
                  btnFilterCustom: survivorDomisticViolenceChecked
                })}
              >
             Survivor of Domestic Violence 
              </Button>
              <Button
                bsPrefix="super-btn"
                value={homelessChecked}
                onClick={(e) => {
                  handleFilterMentalHomelessKeyWord();
                }}
                className={classNames({
                  keyWords: true,
                  btnFilterCustom: homelessChecked
                })}
              >
                Homeless
              </Button>

              <Button
                bsPrefix="super-btn"
                value={deafHearingImpairedChecked}
                onClick={(e) => {
                  handleFilterMentalDeafHearingImpairedKeyWord();
                }}
                className={classNames({
                  keyWords: true,
                  btnFilterCustom: deafHearingImpairedChecked
                })}
              >
               Deaf/hearing impaired 
              </Button>
              <Button
                bsPrefix="super-btn"
                value={intellectualDisabilityChecked}
                onClick={(e) => {
                  handleFilterMentalIntellectualDisabilityKeyWord();
                }}
                className={classNames({
                  keyWords: true,
                  btnFilterCustom: intellectualDisabilityChecked
                })}
              >
               Intellectual disability 
              </Button>

              <Button
                bsPrefix="super-btn"
                value={returningCitizensChecked}
                onClick={(e) => {
                  handleFilterMentalReturningCitizensKeyWord();
                }}
                className={classNames({
                  keyWords: true,
                  btnFilterCustom: returningCitizensChecked
                })}
              >
                Returning citizens 
              </Button>
        </>
        );
}
export default MentalHealthKeywordButtons