import React, { } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Citation from "./Citation";

function HousingPlanFAQ(props) {
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h3>Finding Housing In Your Area Starts With You</h3> 
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>FAQ</h4>
          <b>1. What is public housing?</b>
          <p>Public housing programs are run by the U.S. Department of Housing and Urban Development also referred to as HUD, 
            which provides safe and decent housing for low-income families, the elderly, and people with disabilities. Some people may not be eligible for public housing based on their criminal record; 
            check with the staff member you are working with to make sure you are eligible to apply.
          </p>
          <b>How can I apply for public housing?</b>
          <p>The U.S. Department of Housing and Urban Development was created to provide quality and affordable housing for all Americans. Click on the “State Info” link and find your state for a more detailed look at how HUD can help you.
            If you are interested in getting assistance with finding housing, head over to http://www.hud.gov/offices/hsg/sfh/hcc/hcs.cfm.
          </p>
          <b>What are Section 8 Vouchers?</b>
          <p>Public Housing Agencies can provide help obtaining public housing or Section 8 vouchers. These vouchers can be used to help you pay rent in private apartments. Most housing assistance programs are local, meaning that you need to find out what programs exist in your area. 
            You can find your local public housing agency by searching online using terms such as “(your town or city’s) Public Housing Agency.”
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Citation/>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default HousingPlanFAQ;
