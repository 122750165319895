import React, { } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Citation from "./Citation";

function MaintainingEmployment(props) {
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           <h3>Finding Potential Employment in the Future</h3> 
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Maintaining Employment</h4>
          <p>
            Tips for Maintaining Employment
            <ul>
              <li>
                <u>Understand your role and expectations</u>
                <ul>
                  <li>
                    You may feel as though you are doing a fantastic job, but if
                    your expectations differ from those of your manager or boss,
                    there is going to be conflict. Have a good understanding of what
                    is expected of you. If you feel that the expectations are
                    unrealistic, then you need to approach your supervisor and
                    explain the situation.
                  </li>
                </ul>
              </li>

              <li>
                <u>Be a team player</u>
                <ul>
                  <li>
                    One of the most important aspects of maintaining a professional
                    image is being a good team player and contributing fairly to the
                    team objectives. No one likes a colleague who does not do their
                    fair share of the work. Be willing to share your expertise, but
                    also be willing to learn new skills.
                  </li>
                </ul>
              </li>

              <li>
                <u>Be visible</u>
                <ul>
                  <li>
                    Leaving early every day and not attending social gatherings is
                    not the right way to maintain a professional image. Being
                    visible is all part of being a good team player. This does not
                    mean you have to be best friends with every person you work
                    with, but there is no need to isolate yourself either.
                  </li>
                </ul>
              </li>

              <li>
                <u>
                  Ask for feedback; learn from your supervisor and don’t be afraid
                  to ask for help
                </u>
                <ul>
                  <li>
                    Do not be afraid to ask for feedback. To grow and improve
                    ourselves, we need feedback on how we are performing in our
                    jobs. Positive feedback is always fantastic, however don’t be
                    put down by negative feedback and certainly do not hold grudges.
                    Learn from your mistakes and strive to do better going forward.
                  </li>
                </ul>
              </li>

              <li>
                <u>Be on time</u>
                <ul>
                  <li>
                    Whether it is with showing up for work, returning from breaks,
                    going to meetings, or turning in assignments.
                  </li>
                </ul>
              </li>

              <li>
                <u>Keep your supervisor informed</u>
                <ul>
                  <li>
                    If you are running late, having a difficult time getting to
                    work, or will be absent, let your supervisor know. This is part
                    of gaining his/her confidence.
                  </li>
                </ul>
              </li>

              <li>
                <u>Try your best</u>
                <ul>
                  <li>
                    Always finish an assignment, no matter how much you would rather
                    be doing something else. It is always good to have something to
                    show for the time you have spent.
                  </li>
                </ul>
              </li>

              <li>
                <u>Show a positive attitude</u>
                <ul>
                  <li>
                    The more you stay positive, even if you're in a tough situation,
                    the better you'll be able to manage.
                  </li>
                </ul>
              </li>

              <li>
                <u>Follow the rules</u>
                <ul>
                  <li>
                    The rules are there to give the greatest number of people the
                    best chance of working together well and getting the job done.
                  </li>
                </ul>
              </li>

              <li>
                <u>
                  Avoid the temptation to criticize your company, coworkers, or
                  customers online
                </u>
                <ul>
                  <li>
                    Social networking sites like TikTok, Facebook, Twitter, and
                    blogs offer many opportunities to spout off - remember that
                    anyone in the world can find what you put online and that
                    employers may be able to take action against any employee whose
                    online actions hurt the company or its business in some way.
                  </li>
                </ul>
              </li>

              <li>
                <u>Try to avoid ever saying "that's not my job”</u>
                <ul>
                  <li>
                    Many, if not most, managers earned their positions by doing work
                    turned down by coworkers who were in the habit of saying that,
                    and they appreciate employees who help get the job done,
                    whatever it is.
                  </li>
                </ul>
              </li>

              <li>
                <u>Show pride in yourself and respect toward others</u>
                <ul>
                  <li>
                    Never let yourself be heard uttering minority-related slurs or
                    other derogatory terms in reference to yourself or to others.
                    Use of such terms perpetuates undesirable stereotypes and
                    inevitably disturbs others. It also tends to make others doubt
                    your maturity and competence. The best way to get respect is to
                    show respect toward yourself and others.
                  </li>
                </ul>
              </li>

              <li>
                <u>Distinguish yourself</u>
                <ul>
                  <li>
                    Pick out one or more things in your job to do better than anyone
                    else. Become known as the "go-to" person for such things. That
                    will help managers remember you favorably at times when you
                    really need to be remembered.
                  </li>
                </ul>
              </li>
            </ul>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Citation/>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default MaintainingEmployment;
