import React, {  } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Citation from "./Citation";

function AddressingCriminalHistory(props) {
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           <h3>Finding Potential Employment in the Future</h3> 
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Addressing a period of incarceration/criminal history</h4>
          <p>
            It is important to make your resume as effective as possible so that you
            can get a job interview and the job you are applying for. It is
            important to be honest on your resume, application, and in your
            face-to-face interview.
            <ul>
              <li>
                When completing job applications you will be asked about your
                criminal record. Remember to be honest and select “yes” when asked
                if you have ever been arrested. In the details section, you can
                write “will explain in interview.”.
              </li>
              <li>
                If the person you are interviewing with asks you a legal question
                about your criminal history, explain what happened, but keep it
                positive and let the individual know you are interested in making a
                positive contribution and change to your family, community, and the
                employer.
              </li>
              <li>
                It is important to highlight related training and work experience
                even if this work was completed while in prison or jail. In your
                resume’s Work Experience section, write the name of the facility,
                your title, the dates you worked in this position, and your
                responsibilities.
              </li>
            </ul>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Citation/>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddressingCriminalHistory;
