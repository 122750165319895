import React, {} from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Citation from "./Citation";

function MedicationCompliance(props) {
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           <h3>Finding Potential Mental Health Services</h3> 
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <p><b><u>Medication Compliance</u></b></p>
          <p>To ensure that you stay as healthy as possible, you need to sustain your current treatment 
            routine. Your treatment routine includes taking your medication on regular basis, 
            whether that’s a daily, weekly, or monthly basis. Being compliant in this facet of 
            your life, means that you can focus your attention on other needed areas! </p>
          <p><b><u>Medication Tracker</u></b></p>
          <p>In order to maintain your medication regimen, you have to know what is necessary for 
            you to take on a regular basis.</p>
          <p><b><u>Thinking Report</u></b></p>
          <p>Think of a time when you broke a rule or hurt someone. This could 
            mean breaking the law and it could mean hurting someone emotionally or physically. 
            Complete a thinking report on what happened. Focus on the thinking that led you to break 
            the rule or hurt someone, as opposed to what you thought or felt afterward.
              Step 1: Write a brief, factual description of the situation
              
              Step 2: Write down as many thoughts as you can remember having at that time.
              
              Step 3: Write down all the feelings you remember having at the time.
              
              Step 4: List one or two attitudes or beliefs that were behind your thinking in this 
              situation. Now it is your turn to create your own thinking report. Use 
              a spare piece of paper to practice! </p>
          <p><b><u>Making Amends</u></b></p>
          <p>Restorative Justice is a term that is used that seeks to repair harms caused by 
            criminal activity. While crime does involve the breaking of laws, restorative justice 
            focuses on the breaking of relationships within a community and how this breakage can 
            impact the lives of people. The goals of restoration includes full restitution for victims,
             full reintegration of those who committed the crime, and full involvement for communities.

              In what ways might you be able to repair harm in the lives of people/places you hurt by 
              the criminal activity you engaged in? Provide a few ideas on a spare piece of paper. 

              What would you say to the person/places you harmed, if you could, to repair harm? 
              Write it down! 

              How could you engage with the community in a positive way to restore relationships? 
              Write it down!
              </p>
          <p><b><u>Thinking Errors</u></b></p>
          <p>Everyone has cycles they repeatedly do in their everyday lives. 
            These can include a morning routine, smoking cigarettes, eating, or doing 
            illegal activities. These cycles stem from thinking errors that allow you to 
            rationalize engaging in criminal activity. However, those can be identified and changed. 
            First, you have to identify your cycles and what they are made of to do so. These cycles 
            consist of a few concepts:
 
            (PERCEPTIONS, THOUGHTS, FEELINGS, BEHAVIORS)
              
            Perceptions are things that we see, touch, hear, or smell.
            We then have thoughts about those perceptions.
            Our thoughts about those perceptions then affect how we feel.
            Lastly, our feelings drive our behaviors/actions.
            For example:
            Perceptions (You see a pizza commercial on TV.)
            Thought (“Wow, that looks good!”)
            Feeling (Hunger)
              Action (Pick up the phone and order a pizza.) 
          </p>
          <p><b><u>Writing Thoughts</u></b></p>
          <u>On a spare piece of paper, write down examples of a behavioral chain from your own life that represents a cycle you have.</u>  
          <p>
          Writing this information down can help you identify when you’ve had thinking errors, 
          or thoughts that allow you to rationalize your action. It is important to understand 
          and recognize the difference between positive thoughts and thinking errors that may lead 
          to your offending. If you can identify between the two, then you will be more likely to 
          stop your behaviors that are based on thinking errors that may lead you back into trouble.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Citation/>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default MedicationCompliance;
