import React, { } from "react";
function OrgRightContent({unit_org}) {
    return (
        <>
            <div className="section">
                <div className="secttl">Organization</div>
                <div className="secsubttl">{unit_org.name}</div>
            </div>
            <div className="section">
                <div className="secttl">Address</div>
                <div className="secsubttl">{unit_org.address}</div>
            </div>
            <div className="section">
                <div className="secttl">Zip Code</div>
                <div className="secsubttl">{unit_org.zip_code}</div>
            </div>
            <div className="section">
                <div className="secttl">Type</div>
                <div className="secsubttl">{unit_org.type}</div>
            </div>
            <div className="section">
                <div className="secttl">Description</div>
                <div className="secsubttl">{unit_org.description}</div>
            </div>
            <div className="section">
                <div className="secttl">Services</div>
                <div className="secsubttl">{unit_org.services}</div>
            </div>
            <div className="section">
                <div className="secttl">Criteria</div>
                <div className="secsubttl">{unit_org.criteria}</div>
            </div>
            </>
        );

}

export default OrgRightContent