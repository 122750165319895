import React, { } from "react";
import classNames from "classnames";
function OrgLeftMenu({totalOrg, orgData, getOrganizations, checkActive}) {
    return (
        <>
            <h5>Total Organizations: {totalOrg}</h5>
                {orgData.map(function(org) {
                return (
                    <div
                    onClick={() => getOrganizations(org.org_id)}
                    className={classNames({
                        ldisp: true,
                        active: checkActive(org.org_id),
                    })}
                    >
                    <div className="ttl">{org.name}</div>
                    <div className="subttl">{org.type}</div>
                    </div>
                );
            })}
        </>
    );
}

export default OrgLeftMenu