import React, { } from "react";
import classNames from "classnames";
import { Button } from "react-bootstrap";
function OtherPagesKeywordButtons({checked,handleChangePoupFilterByPhotoID}) {
    return (
        <>
            <Button
              bsPrefix="super-btn"
              value={checked}
              onClick={(e) => {
                handleChangePoupFilterByPhotoID();
              }}
              className={classNames({
                keyWords: true,
                btnFilterCustom: checked
              })}
              >
              Photo ID
            </Button>
        </>
        );
}
export default OtherPagesKeywordButtons