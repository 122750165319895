import React, { } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Citation from "./Citation";

function FindingHousing(props) {
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           <h3>Finding Housing In Your Area Starts With You</h3> 
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Finding Housing In Your Area Starts With You</h4>
          <p>
            <u><h6>Talk to your support system about housing!</h6></u>
            <p>
            The people around you have experience 
            searching for housing. You should rely on their experience and ask 
            them to get information on local housing resources for you.
            </p>
            <u><h6>Keep Track of Potential Housing</h6></u>
            <p>
            Once you have started to find potential places to live, <b>make a list of organizations that would be interested in working with.</b>
            Call these organizations to see if it is a good fit for you!
            </p>
            <u><h6>Visiting Potential Permanent Places to Live</h6></u>
            <p>
            When you go for the tour, make sure to be on the lookout for things 
            that are good and bad. There are many things that property owners usually ask for 
            when looking at your application such as identification (driver’s license, state ID), 
            a credit check, proof of employment or vouchers, and possibly criminal history. 
            Find out what each property owner requires. It is not easy to find housing with a 
            criminal background. 
            <u>Do not let this discourage you!</u> <b>The following tips can help you face this challenge:</b>
            <ul>
              <li><b>Make a good first impression.</b> Go to your appointment with a positive attitude. 
                Be sincere, polite and patient. It is important that the potential landlord 
                believes she/he could have a positive relationship with you.</li>
              <li><b>Be honest.</b> If asked, be honest and let potential landlords know about your criminal 
                record. Explain briefly what you were convicted for and avoid taking a defensive tone. 
                Answer any questions the landlord asks. Explain what you have learned from your past 
                and why you would be a good tenant today. Focus on asking questions relating to your 
                housing needs only.
              </li>
              <li><b>Be confident.</b> Not every landlord is going to be accepting. Focus on what you can 
                control—making a good impression—and if it does not work out, then move on to the next 
                housing opportunity.
              </li>
            </ul>
            </p>
            <u><h6>The following are some questions you may want to ask when looking at a new place:</h6></u>
            <p>
            <ul>
              <li>Does it have a washer and dryer (either in-unit or on the property)?</li>
              <li>Are utilities included in the monthly fees or are they separately charged?</li>
              <li>Is renter’s insurance required? If so, how much?</li>
              <li>Are pets allowed? If so, is there a fee?</li>
              <li>Is there an application or administrative fee upfront?</li>
              <li>Is there a fee if you need to break your lease for any reason?</li>
              <li>Is there a fee if your late paying rent one month?</li>
              <li>Is the place in good, clean condition? Is anything damaged or broken?</li>
              <li>Is there enough space for my family and others living with me, including pets?</li>
              <li>Is this place near public transportation such as the metro or bus stop, if needed?</li>
            </ul>
            </p>
            <u><h6>You found a place to live! What’s next?</h6></u>
            <p>
            Make sure you review and sign a lease with your landlord to protect yourself. 
            The lease should list several important things.
            </p>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Citation/>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default FindingHousing;
