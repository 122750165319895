import React, {} from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Citation from "./Citation";

function EnteringRecovery(props) {
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
             <h3>Finding Potential Substance Use Information</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <p><b><u>Entering Recovery</u></b> </p>
          <p>Getting to recovery can be incredibly challenging, but you can do it! 
          Here are some things you can do to stay on track with your goal of recovery.</p>
          <p>
          <ul>
              <li>
                <u>Set a realistic goal date to stop using substances</u> Recognizing that you 
                have a drug or alcohol problem is a huge step and one that many people have trouble 
                with.Once you realize there are some things in your life that you should change, 
                the best way to start on your road to success is to make a goal.
                Setting a realistic date of when you would like to completely stop using by 
                can be very difficult because it may be far in the future. However, it will 
                be something to work towards and it will be a good measure of your progress.
              </li>
              <li>
                <u>Stay motivated and make a positive choice in your life today.</u> A positive choice 
                such as not drinking alcohol for one day can make you feel good about yourself and lead 
                you to make other good choices.It can be very hard to avoid temptations and old habits 
                and you shouldn’t get down on yourself if you slip. One way to keep motivated is to 
                make a positive choice such as going for a walk with your family instead of going to 
                the bar.
              </li>
              <li>
                <u>Go without drugs or alcohol today.</u> It can be really hard to take the 
                first steps towards recovery. Taking it one day at a time will show you that 
                you can do it! Be proud of yourself for going one day without drugs and alcohol.
              </li>
              <li>
                <u>Check out books from your local library, download audio books, or check out podcasts.</u> 
                that may help guide you through staying in recovery. Try using search words such 
                as “substance use disorder” and “recovery” in your favorite website or app.
              </li>
              <li>
                <u>Write down a list of triggers</u> on a spare piece of paper that have caused you to 
                continue using drugs or alcohol in the past. Triggers are people, places, 
                things, situations, or behaviors that are reminders of previous alcohol and drug 
                use, and that may act as reminders or reasons to resume use.
                You can look back at this list of triggers to see what or whom you should avoid 
                in order help you stay on the path to successful recovery
              </li>
              <li>
                <u>Write down how you will deal with each of the triggers on your list.</u> This will 
                help you develop an action plan when you are in tough situations.For example, if you 
                usually drink too much when you’re around your friends, write down things you can 
                say to them if they pressure you to drink. Making plans ahead of time can help 
                you be successful when you get into tough situations.
              </li>
              <li>
                <u>Talk with family and friends about how your drug/alcohol use is affecting them</u> 
                with family and friends about how your drug/alcohol use is affecting them. You may not 
                realize that your behavior hurts other people, but it is never too late to start making 
                changes! The people closest to you will appreciate your efforts. It is also helpful 
                to surround yourself with people who support you.
              </li>
              <li>
                <u>Making major life changes</u> 
                can be really tough and you should be proud of yourself for taking the first steps 
                towards recovery. Take a moment today to remind yourself why stopping substance use 
                is important and keep up the good work! On a spare piece of paper, write down some 
                reminders of why it’s important to be successful for you. 
              </li>
              <li>
                <u>There are many ways to help deal with these stressful times.</u> 
                Think of three things you can do to deal with tough situations that do 
                not involve drugs or alcohol. Write them down!Some examples are exercising, 
                going for a walk, 
                playing sports, taking deep breaths, or listening to calming music. There are no right 
                or wrong answers; you just need to figure out what works best for you!
              </li>
              <li>
                <u>Reflect back on the suggestions for staying in recovery provided in this section.</u> 
                Which suggestions do you think will work best for you? Think about ways that will help 
                you remain in recovery, whether that is regularly attending self-help meetings or 
                talking to professional staff you work with regularly about how to keep from 
                returning to use, it is important to find the things that work best for you.
              </li>
            </ul>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Citation/>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EnteringRecovery;
