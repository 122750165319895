import React, { useState, useEffect } from "react";
import "../App.css";
import Axios from "axios";
import "bootstrap/dist/css/bootstrap.css";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookmark as fasB } from "@fortawesome/free-solid-svg-icons";
import { faBookmark as farB } from "@fortawesome/free-regular-svg-icons";
import Popup from "../common/Popup";
import CommonNav from "../common/CommonNav";
import CommonOrgPageHeader from "../common/CommonOrgPageHeader";
import PaginationBar from "../common/PaginationBar";
import OrgLeftMenu from "../common/OrgLeftMenu";
import OrgRightContent from "../common/OrgRightContent";
import HousingKeywordButtons from "./HousingKeywordButtons";
import HealthKeywordButtons from "./HealthKeywordButtons";
import SubstanceKeywordButtons from "./SubstanceKeywordButtons";
import MentalHealthKeywordButtons from "./MentalHealthKeywordButtons";
import OtherPagesKeywordButtons from "./OtherPagesKeywordButtons";
import { useGlobalConfigContext } from "../App";

function OrgPageTemplate({pageName,tittlePage}) {
  const serverDomain   = useGlobalConfigContext()["serverDomain"];
  const history = useHistory();
  const [unit_org, setUnitOrgData] = useState([]);
  const [active, setActive] = useState(false);
  const [favData, setFavData] = useState([]);
  Axios.defaults.withCredentials = true;
  const [buttonPoup, setButtonPoup] = useState(false);
  const [checked, setChecked] = useState(false);
  
  // Keywords for housing page
  //TODO 1 check menCheckedString as Object

  const [menChecked, setMenChecked] = useState(false);
  const [womenChecked, setWomenChecked] = useState(false);
  const [familiesChecked, setFamiliesChecked] = useState(false);
  const [socialSercurityChecked, setSocialSercurityChecked] = useState(false);
  const [plus60Checked, setPlus60Checked] = useState(false);
  const [substanceFreeChecked, setSubstanceFreeChecked] = useState(false);
  const [hivPositiveChecked, setHIVPositiveChecked] = useState(false);
  const [survivorDomisticViolenceChecked, setSurvivorDomisticViolenceChecked] = useState(false);

  // Keywords for health page
  const [photoIDChecked, setPhotoIDChecked] = useState(false);
  const [substanceUseChecked, setSubstanceUseChecked] = useState(false);
  const [residentVirginiaChecked, setResidentVirginiaChecked] = useState(false);
  const [pregnantChecked, setPregnantChecked] = useState(false);

  // Keywords for Substance use page
  const [mentalIllnessChecked, setMentalIllnessChecked] = useState(false);
  const [medicaidChecked, setMedicaidChecked] = useState(false);
  const [medicareChecked, setMedicareChecked] = useState(false);

  // Keywords for Mental page
  const [seriousMentalIllnessChecked, setSeriousMentalIllnessChecked] = useState(false);
  const [chronicIllnessChecked, setChronicIllnessChecked] = useState(false);
  const [traumaChecked, setTraumaChecked] = useState(false);
  const [homelessChecked, setHomelessChecked] = useState(false);
  const [deafHearingImpairedChecked, setDeafHearingImpairedChecked] = useState(false);
  const [intellectualDisabilityChecked, setIntellectualDisabilityChecked] = useState(false);
  const [returningCitizensChecked, setReturningCitizensChecked] = useState(false);

  // const for paging
  const [offset, setOffset] = useState(0);
  const [orgData, setOrgData] = useState([]);
  const [perPage] = useState(4);
  const [pageCount, setPageCount] = useState(0);
  const [selectedPage, setselectedPage] = useState(1);
  const [totalOrg, setTotalOrg] = useState(0);
  const nextClickPaging = (e) => {
    setOffset(offset + perPage);
    setselectedPage(selectedPage + 1);
  };
  const lastClickPaging = (e) => {
    if (totalOrg % perPage === 0) {
      setOffset(totalOrg - perPage);
    } else {
      setOffset(totalOrg - (totalOrg % perPage));
    }
    setselectedPage(pageCount);
  };

  const preClickPaging = (e) => {
    setOffset(offset - perPage);
    setselectedPage(selectedPage - 1);
  };
  const firstClickPaging = (e) => {
    setOffset(0);
    setselectedPage(1);
  };

  // BEGIN handle filter for housing page
  const handleFilterHousingMenKeyWord = () => {
    setMenChecked(!menChecked)
    setOffset(0)
    setselectedPage(1);
  };
  const handleFilterHousingWomenKeyWord = () => {
    setWomenChecked(!womenChecked);
    setOffset(0)
    setselectedPage(1);
  };

  const handleFilterHousingFamiliesKeyWord = () => {
    setFamiliesChecked(!familiesChecked);
    setOffset(0)
    setselectedPage(1);
  };
  const handleFilterHousingSocialSercurityKeyWord = () => {
    setSocialSercurityChecked(!socialSercurityChecked);
    setOffset(0)
    setselectedPage(1);
  };
  const handleFilterHousingPlus60KeyWord = () => {
    setPlus60Checked(!plus60Checked);
    setOffset(0)
    setselectedPage(1);
  };
  const handleFilterHousingSubstanceFreeKeyWord = () => {
    setSubstanceFreeChecked(!substanceFreeChecked);
    setOffset(0)
    setselectedPage(1);
  };
  const handleFilterHousingHIVPositiveKeyWord = () => {
    setHIVPositiveChecked(!hivPositiveChecked);
    setOffset(0)
    setselectedPage(1);
  };
  const handleFilterHousingSurvivorDomisticViolenceKeyWord = () => {
    setSurvivorDomisticViolenceChecked(!survivorDomisticViolenceChecked);
    setOffset(0)
    setselectedPage(1);
  };
  // END handle filter for housing page

    // BEGIN handle filter for health page
    const handleFilterHealthPhotoIdKeyWord = () => {
      setPhotoIDChecked(!photoIDChecked)
      setOffset(0)
      setselectedPage(1);
    };
    const handleFilterHealthSubstanceUseKeyWord = () => {
      setSubstanceUseChecked(!substanceUseChecked);
      setOffset(0)
      setselectedPage(1);
    };
  
    const handleFilterHealthResidentVirginiaKeyWord = () => {
      setResidentVirginiaChecked(!residentVirginiaChecked);
      setOffset(0)
      setselectedPage(1);
    };
    const handleFilterHealthPregnantKeyWord = () => {
      setPregnantChecked(!pregnantChecked);
      setOffset(0)
      setselectedPage(1);
    };
    // END handle filter for health page

    // BEGIN handle filter for substance use page
    const handleFilterSubstanceUseSubstanceUseKeyWord = () => {
      setSubstanceUseChecked(!substanceUseChecked);
      setOffset(0)
      setselectedPage(1);
    };
    const handleFilterSubstanceUseMentalIllnessKeyWord = () => {
      setMentalIllnessChecked(!mentalIllnessChecked);
      setOffset(0)
      setselectedPage(1);
    };
    const handleFilterSubstanceUseMedicaidKeyWord = () => {
      setMedicaidChecked(!medicaidChecked);
      setOffset(0)
      setselectedPage(1);
    };
    const handleFilterSubstanceUseMedicareKeyWord = () => {
      setMedicareChecked(!medicareChecked);
      setOffset(0)
      setselectedPage(1);
    };
    // END handle filter for substance use page

    // BEGIN handle filter for mental  page
    const handleFilterMentalMedicaidKeyWord = () => {
      setMedicaidChecked(!medicaidChecked);
      setOffset(0)
      setselectedPage(1);
    };
    const handleFilterMentalMedicareKeyWord = () => {
      setMedicareChecked(!medicareChecked);
      setOffset(0)
      setselectedPage(1);
    };
    const handleFilterMentalSeriousMentalIllnessdKeyWord = () => {
      setSeriousMentalIllnessChecked(!seriousMentalIllnessChecked);
      setOffset(0)
      setselectedPage(1);
    };
    const handleFilterMentalChronicIllnessKeyWord = () => {
      setChronicIllnessChecked(!chronicIllnessChecked);
      setOffset(0)
      setselectedPage(1);
    };
    const handleFilterMentalTraumaKeyWord = () => {
      setTraumaChecked(!traumaChecked);
      setOffset(0)
      setselectedPage(1);
    };
    const handleFilterMentalSurvivorDomisticViolenceKeyWord = () => {
      setSurvivorDomisticViolenceChecked(!survivorDomisticViolenceChecked);
      setOffset(0)
      setselectedPage(1);
    };
    const handleFilterMentalHomelessKeyWord = () => {
      setHomelessChecked(!homelessChecked);
      setOffset(0)
      setselectedPage(1);
    };
    const handleFilterMentalDeafHearingImpairedKeyWord = () => {
      setDeafHearingImpairedChecked(!deafHearingImpairedChecked);
      setOffset(0)
      setselectedPage(1);
    };
    const handleFilterMentalIntellectualDisabilityKeyWord = () => {
      setIntellectualDisabilityChecked(!intellectualDisabilityChecked);
      setOffset(0)
      setselectedPage(1);
    };
    const handleFilterMentalReturningCitizensKeyWord = () => {
      setReturningCitizensChecked(!returningCitizensChecked);
      setOffset(0)
      setselectedPage(1);
    }

    // handle filter for other pages
    const handleChangePoupFilterByPhotoID = () => {
      setChecked(!checked);
      setOffset(0)
      setselectedPage(1);
    };

  // END handle filter for mental page
  const getOrganizations = (id) => {
    Axios.post( serverDomain+"/organization/getOrgById", {
      pageName: pageName,
      org_id:id
    }).then((response) => {
      setActive(id);
      setUnitOrgData(response.data[0]);
    });
  };

  const checkActive = (id) => { return id === active }

  const isFavorite = (id) => {
    for (let i = 0; i < favData.length; i++) {
      if (favData[i].org_id === id) {
        return true;
      }
    }
    return false;
  };

  const getFavOrganizations = () => {
    Axios.get( serverDomain+"/favorites/").then((response) => {
      setFavData(response.data);
    });
  };

  const addToFavorites = (obj) => {
    Axios.post( serverDomain+"/favorites/", {
      organization_id: obj.org_id,
    })
      .then(function(response) {
        console.log("obj.org_id " + obj.org_id);
        getInit(obj.org_id);
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  const removeFromFavorites = (obj) => {
    Axios.delete( serverDomain+"/favorites/", {
      data: {
        organization_id: obj.org_id,
      },
    })
      .then(function(response) {
        console.log("obj.org_id " + obj.org_id);
        getInit(obj.org_id);
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  const changeStatus = (obj) => {
    if (isFavorite(obj.org_id)) {
      removeFromFavorites(obj);
    } else {
      addToFavorites(obj);
    }
  };

  // check filter or not 
  const getInit = (orgId) => {
    if (menChecked ===false && womenChecked === false  && familiesChecked === false && socialSercurityChecked === false && plus60Checked === false && substanceFreeChecked === false && hivPositiveChecked === false && survivorDomisticViolenceChecked === false
      && photoIDChecked === false  && substanceUseChecked === false && residentVirginiaChecked === false && pregnantChecked === false
      && mentalIllnessChecked === false && medicaidChecked === false && medicareChecked === false
      && seriousMentalIllnessChecked === false  && chronicIllnessChecked === false && traumaChecked === false && homelessChecked === false && deafHearingImpairedChecked === false  && intellectualDisabilityChecked === false && returningCitizensChecked === false
        && checked === false) {
        Axios.post( serverDomain+"/organization/getAll", {
          pageName: pageName
        }).then((response) => {
          console.log(response.data[0].count)
          setTotalOrg(response.data[0].count);
          setPageCount(Math.ceil(response.data[0].count / perPage));
        });
      Axios.post( serverDomain+"/organization", {
        offset: offset,
        perPage: perPage,
        pageName: pageName
      }).then((response) => {
        if(response.data.length > 0) {
          setOrgData(response.data);
          if (orgId <= 0) {
            orgId = response.data[0].org_id;
          }
          getOrganizations(orgId);
          getFavOrganizations();
        }
      });
    } else {
      let listKeyWord = [];
      // housing
      if(menChecked) { listKeyWord.push("Men") }
      if(womenChecked) { listKeyWord.push("Women") }
      if(familiesChecked) { listKeyWord.push("Families") }
      if(socialSercurityChecked) { listKeyWord.push("Social Security") }
      if(plus60Checked) { listKeyWord.push("60+") }
      if(substanceFreeChecked) { listKeyWord.push("Substance free")}
      if(hivPositiveChecked) {listKeyWord.push("HIV positive")}
      if(survivorDomisticViolenceChecked) { listKeyWord.push("Survivor of Domestic Violence")}

      // health
      if(photoIDChecked) {listKeyWord.push("Photo ID") }
      if(substanceUseChecked) { listKeyWord.push("Substance use") }
      if(residentVirginiaChecked) { listKeyWord.push("Resident of Virginia")}
      if(pregnantChecked) { listKeyWord.push("Pregnant")}

      // substance use: some varibles are reused from other pages
      if(mentalIllnessChecked) { listKeyWord.push("Mental illness")}
      if(medicaidChecked) { listKeyWord.push("Medicaid")}
      if(medicareChecked) { listKeyWord.push("Medicare") }

      // mental page: some varibles are reused from other pages
      if(seriousMentalIllnessChecked) { listKeyWord.push("Serious Mental Illness") }
      if(chronicIllnessChecked) { listKeyWord.push("Chronic Illness") }
      if(traumaChecked) { listKeyWord.push("Trauma")}
      if(homelessChecked) { listKeyWord.push("Homeless") }
      if(deafHearingImpairedChecked) { listKeyWord.push("Deaf/hearing impaired") }
      if(intellectualDisabilityChecked) {listKeyWord.push("Intellectual disability")}
      if(returningCitizensChecked) { listKeyWord.push("Returning citizens")}

      // other pages
      if(checked) { listKeyWord.push("Photo ID") }

      Axios.post( serverDomain+"/organization/getAllHasID",{
        keyWords : listKeyWord,
        pageName: pageName
      }).then(
        (response) => {
          setTotalOrg(response.data[0].count);
          setPageCount(Math.ceil(response.data[0].count / perPage));
        }
      );
      Axios.post( serverDomain+"/organization/hasID", {
        keyWords : listKeyWord,
        offset: offset,
        perPage: perPage,
        pageName: pageName
      }).then((response) => {
        if(response.data.length > 0) {
          setOrgData(response.data);
          if (orgId <= 0) {
            orgId = response.data[0].org_id;
          }
          getOrganizations(orgId);
          getFavOrganizations();
        }
      });
    }
  };
  useEffect(() => {
    document.title = tittlePage;  
    Axios.get( serverDomain+"/login").then((response) => {
      if (response.data.loggedIn === true) {
        getInit(0);
      } else {
        history.push("/login");
      }
    });
  }, [offset,checked,
    menChecked,womenChecked,familiesChecked,socialSercurityChecked,plus60Checked,substanceFreeChecked,hivPositiveChecked,survivorDomisticViolenceChecked,
    photoIDChecked,substanceUseChecked,residentVirginiaChecked,pregnantChecked,
    mentalIllnessChecked,medicaidChecked,medicareChecked,
    seriousMentalIllnessChecked,chronicIllnessChecked,traumaChecked,homelessChecked,deafHearingImpairedChecked,intellectualDisabilityChecked,returningCitizensChecked
  ]);
  useEffect(() => {
    Axios.post( serverDomain+"/login/insertLoginTrackerByIdAndPageName", {
      page_name: pageName,
    }).then(function(response) {
      console.log(pageName)
    })
    .catch(function(error) {
      console.log(error);
    })
  }, [])
  return (
    <div className="container home">
      <CommonNav />
      <CommonOrgPageHeader tittlePage= {tittlePage}/>
      <div className="org_filter">
        <main>
          <Button
            onClick={() => setButtonPoup(true)}
            className="fullWidth"
            variant="primary"
          >
            Filter Organizations
          </Button>
        </main>

        {/* BEGIN housing page  */}
        {pageName === "housing" &&
          <Popup trigger={buttonPoup} setTrigger={setButtonPoup}>
            <HousingKeywordButtons menChecked = {menChecked} handleFilterHousingMenKeyWord = {handleFilterHousingMenKeyWord}
                              womenChecked = {womenChecked} handleFilterHousingWomenKeyWord = {handleFilterHousingWomenKeyWord}
                              familiesChecked = {familiesChecked} handleFilterHousingFamiliesKeyWord = {handleFilterHousingFamiliesKeyWord}
                              socialSercurityChecked = {socialSercurityChecked} handleFilterHousingSocialSercurityKeyWord = {handleFilterHousingSocialSercurityKeyWord}
                              plus60Checked = {plus60Checked} handleFilterHousingPlus60KeyWord = {handleFilterHousingPlus60KeyWord}
                              substanceFreeChecked = {substanceFreeChecked} handleFilterHousingSubstanceFreeKeyWord = {handleFilterHousingSubstanceFreeKeyWord}
                              hivPositiveChecked = {hivPositiveChecked} handleFilterHousingHIVPositiveKeyWord = {handleFilterHousingHIVPositiveKeyWord}
                              survivorDomisticViolenceChecked = {survivorDomisticViolenceChecked} handleFilterHousingSurvivorDomisticViolenceKeyWord = {handleFilterHousingSurvivorDomisticViolenceKeyWord}/>
            </Popup>          
        } 
        {/* END housing page  */}

        {/* BEGIN health page  */}
        {pageName === "health" &&
          <Popup trigger={buttonPoup} setTrigger={setButtonPoup}>
            <HealthKeywordButtons photoIDChecked = {photoIDChecked} handleFilterHealthPhotoIdKeyWord = {handleFilterHealthPhotoIdKeyWord}
                              substanceUseChecked = {substanceUseChecked} handleFilterHealthSubstanceUseKeyWord = {handleFilterHealthSubstanceUseKeyWord}
                              residentVirginiaChecked = {residentVirginiaChecked} handleFilterHealthResidentVirginiaKeyWord = {handleFilterHealthResidentVirginiaKeyWord}
                              pregnantChecked = {pregnantChecked} handleFilterHealthPregnantKeyWord = {handleFilterHealthPregnantKeyWord}
                              />
          </Popup>          
        } 
        {/* END health page  */}

         {/* BEGIN substanceUse page  */}
         {pageName === "substanceUse" &&
        <Popup trigger={buttonPoup} setTrigger={setButtonPoup}>
          <SubstanceKeywordButtons substanceUseChecked = {substanceUseChecked} handleFilterSubstanceUseSubstanceUseKeyWord = {handleFilterSubstanceUseSubstanceUseKeyWord}
                  mentalIllnessChecked = {mentalIllnessChecked} handleFilterSubstanceUseMentalIllnessKeyWord = {handleFilterSubstanceUseMentalIllnessKeyWord}
                  medicaidChecked = {medicaidChecked} handleFilterSubstanceUseMedicaidKeyWord = {handleFilterSubstanceUseMedicaidKeyWord}
                  medicareChecked = {medicareChecked} handleFilterSubstanceUseMedicareKeyWord = {handleFilterSubstanceUseMedicareKeyWord}
          />
        </Popup>  
        } 
        {/* END substanceUse page  */}

        {/* BEGIN mentalHealth page  */}
        {pageName === "mentalHealth" &&
          <Popup trigger={buttonPoup} setTrigger={setButtonPoup}>
          <MentalHealthKeywordButtons medicaidChecked = {medicaidChecked} handleFilterMentalMedicaidKeyWord = {handleFilterMentalMedicaidKeyWord}
                             medicareChecked = {medicareChecked} handleFilterMentalMedicareKeyWord = {handleFilterMentalMedicareKeyWord}
                             seriousMentalIllnessChecked = {seriousMentalIllnessChecked} handleFilterMentalSeriousMentalIllnessdKeyWord = {handleFilterMentalSeriousMentalIllnessdKeyWord}
                             chronicIllnessChecked = {chronicIllnessChecked} handleFilterMentalChronicIllnessKeyWord = {handleFilterMentalChronicIllnessKeyWord}
                             traumaChecked = {traumaChecked} handleFilterMentalTraumaKeyWord = {handleFilterMentalTraumaKeyWord}
                             survivorDomisticViolenceChecked = {survivorDomisticViolenceChecked} handleFilterMentalSurvivorDomisticViolenceKeyWord = {handleFilterMentalSurvivorDomisticViolenceKeyWord}
                             homelessChecked = {homelessChecked} handleFilterMentalHomelessKeyWord = {handleFilterMentalHomelessKeyWord}
                             deafHearingImpairedChecked = {deafHearingImpairedChecked} handleFilterMentalDeafHearingImpairedKeyWord = {handleFilterMentalDeafHearingImpairedKeyWord}
                             intellectualDisabilityChecked = {intellectualDisabilityChecked} handleFilterMentalIntellectualDisabilityKeyWord = {handleFilterMentalIntellectualDisabilityKeyWord}
                             returningCitizensChecked = {returningCitizensChecked} handleFilterMentalReturningCitizensKeyWord = {handleFilterMentalReturningCitizensKeyWord}
                             />
          </Popup>          
        } 
        {/* END mentalHealth page  */}

        {(pageName === "basicNeeds" || pageName === "employment" || pageName === "hotline" || pageName === "information" || pageName === "universal") &&
          <Popup trigger={buttonPoup} setTrigger={setButtonPoup}>
            <OtherPagesKeywordButtons checked = {checked} handleChangePoupFilterByPhotoID = {handleChangePoupFilterByPhotoID}/>
          </Popup>          
        } 
      </div>
      {totalOrg > 0 ? (
        <div className="homeDisplay">
          <div className="disp1">
           <OrgLeftMenu totalOrg ={totalOrg} orgData ={orgData} getOrganizations ={getOrganizations} checkActive ={checkActive}/>
          </div>
          <div className="disp2">
            <div className="root">
              <span className="child">
                <FontAwesomeIcon
                  onClick={() => changeStatus(unit_org)}
                  icon={isFavorite(unit_org.org_id) ? fasB : farB}
                />
              </span>
            </div>
            <OrgRightContent unit_org = {unit_org}/>
          </div>
        </div>
      ) : (
        <h3>There is no records</h3>
      )}
        <div className="pagingWrap">
            <PaginationBar selectedPage = {selectedPage} pageCount = {pageCount} 
                           firstClickPaging = {firstClickPaging} 
                           preClickPaging = {preClickPaging} 
                           nextClickPaging = {nextClickPaging}
                           lastClickPaging = {lastClickPaging} />
        </div>
    </div>
  );
}
export default OrgPageTemplate;
