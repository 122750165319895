import React, {} from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Citation from "./Citation";

function PracticeCalmingTechniques(props) {
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           <h3>Finding Potential Mental Health Services</h3> 
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <p><b><u>Practice calming techniques</u></b></p>
          <p>
            <ul>
              <li>
                <u>Meditation:</u>
                <p>A good way to help keep yourself calm is to practice relaxation techniques. There 
                  are many different things you can do to keep yourself calm in a tough situation so 
                  try some of these techniques today.
                  Meditation is something you can do almost anywhere, no matter what you’re doing.
                  Meditation involves focusing on any repetitive action. This could be a word or phrase you s
                  ay over and over in your head or an action such as swimming or running.
                  Whenever you’re having stressful thoughts or start to feel overwhelmed, just 
                  focus on your 
                  task or chant for 5 to 10 minutes and watch your stress go away.
                  There are lots of apps out there to help you with meditation! Try out different 
                  ones to 
                  see what you like.</p>
              </li>
              <li>
                <u>Deep Breathing:</u>
                <p>Close your eyes and relax your shoulders.
                    Breathe in through your nose and out through your mouth.
                    Take big breaths in and push all the air out.
                    Do this 10 times a day, especially when you are feeling very overwhelmed 
                    and you will find yourself more relaxed.</p>
              </li>
              <li>
                <u>Take a time out:</u>
                <p>If you think that you are about to get out of control or upset, take a few 
                  moments to sit or lay down in a quiet place.
                  Take the time to calm yourself down, control your breathing, and focus on relaxing.
                  After 5 or so minutes, you may find that you are ready to continue what 
                  you were doing in a more relaxed state.</p>
              </li>
            </ul>
          </p>

        </Modal.Body>
        <Modal.Footer>
          <Citation/>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PracticeCalmingTechniques;
