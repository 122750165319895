import React, { } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Citation from "./Citation";

function VictimEmpathyActivity(props) {
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           <h3>Finding Potential Mental Health Services</h3> 
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p><b><u>Victim Empathy Activity</u></b></p>
          <p>
          Recall a crime you committed. With this crime in mind, answer the following questions 
          on a spare piece of paper. This exercise will help you consider how your behavior 
          affected your victim(s).
          </p>
          <p>
            <ul>
              <li>Describe the crime you committed.</li>
              <li>Who was your victim? How would you describe him or her?</li>
              <li>Did you know the victim before the crime? If yes, how?</li>
              <li>What did your crime cost the victim?</li>
              <li>Financially?</li>
              <li>Emotionally?</li>
              <li>What effect do you think this crime had on your victim?</li>
              <li>How did your crime affect the victim’s family, friends, and community?</li>
              <li>How would you feel if the crime had been committed against you?</li>
              <li>How would you feel if the crime had been committed against a member of your family?</li>
              <li>What do you think should happen to people who commit this type of crime?</li>
              <li>Are you paying too much or too little for this crime? Why?</li>
            </ul>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Citation/>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default VictimEmpathyActivity;
