import React, { } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Citation from "./Citation";

function PrepareJobInterview(props) {
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           <h3>Finding Potential Employment in the Future</h3> 
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Preparing for a Job interview</h4>
          <p> You got a job interview...what’s next?</p>
          <p> Congratulate yourself! You should be very proud.</p>
          <p>
            Interviews are not easy but each one will help prepare you for the next.
            Do not be discouraged if you do not hear back after the interview.
            Employers often interview many people for only one position.
            <u>Treat each interview as a learning experience.</u>
          </p>

          <p>
            Here are some great tips on how to prepare for job interviews. Feel free
            to try one or all of them to help prepare you for the big day.
            <ul>
              <li>
                <u>Personal hygiene is key!</u>
                <ul>
                  <li>
                    Personal hygiene is always important, but it is extremely
                    important during a job interview. First impressions matter, so
                    showing up to the interview clean, fresh, and ready to go will
                    help you make a great first impression. Here are some tips for
                    maintaining personal hygiene! Bathe regularly, Trim your nails,
                    Brush and floss your teeth, Wash your hands regularly
                  </li>
                </ul>
              </li>

              <li>
                <u>Dress for Success</u>
                <ul>
                  <li>
                    Dressing professionally for a job interview can set a great
                    first impression.
                  </li>
                </ul>
              </li>

              <li>
                <u>Prepare for interview questions!</u> One of the best ways to
                prepare for an interview is to practice answers to commonly asked
                questions. Below is a list of ten of the most commonly asked
                interview questions asked by employers. Write down your responses
                <ul>
                  <li>Tell me about yourself.</li>
                  <li>Why do you want to work for us?</li>
                  <li>What do you know about our company?</li>
                  <li>Why did you leave your past job?</li>
                  <li>Tell me about your experience at your last job</li>
                  <li>
                    What experience do you have doing ______ (fill in the major
                    responsibilities of the job you are applying for)?
                  </li>
                  <li>Tell me about your strengths or things you are good at.</li>
                  <li>
                    Tell me about your weaknesses or things that you are not so good
                    at.
                  </li>
                  <li>
                    Tell me about a time you had to handle a difficult situation at
                    work.
                  </li>
                  <li>What questions do you have for me?</li>
                </ul>
              </li>
            </ul>
          </p>

          <p>
            Asking potential employers questions shows that you are interested in
            their company. Write down at least 3 questions you can ask employers.
            Examples include questions like “What qualities are you looking for in
            potential employees?” or more technical questions like “What are the
            hours for this position?”.
          </p>

          <p>
            <u>Congrats! You completed a job interview… What's next?</u>
          </p>
          <p>
            After your interview, make sure to stand out. Stand out from the crowd
            and send a thank you note to employers after your interview. You can
            send a handwritten thank you note, or if you know their email address,
            you can e-mail it to them. Thanking employers for their time and
            consideration will give you a step up above the rest of the applicant
            pool.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Citation/>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PrepareJobInterview;
