import React, { useState, useEffect } from "react";
import "../App.css";
import Axios from "axios";
import "bootstrap/dist/css/bootstrap.css";
import {
  Card,
  Button
} from "react-bootstrap";
import { useHistory } from "react-router";
import classNames from "classnames";
import CommonNav from "../common/CommonNav";
import { useGlobalConfigContext } from "../App";
import Pagination from "react-bootstrap/Pagination";
import EnteringRecovery from "../static/EnteringRecovery";
import FindingHelp from "../static/FindingHelp";
import AdditionalResourcesFindHelp from "../static/AdditionalResourcesFindHelp";
import OrganizationDetailPopup from "../static/OrganizationDetailPopup";
import MessageNoFavorite from "../static/MessageNoFavorite";

function SubstanceUsePlan() {
  const serverDomain   = useGlobalConfigContext()["serverDomain"];
  const history = useHistory();
  const [unit_org, setUnitOrgData] = useState([]);
  const [active, setActive] = useState(false);

  Axios.defaults.withCredentials = true;
  // const for paging
  const [offset, setOffset] = useState(0);
  const [orgData, setOrgData] = useState([]);
  const [perPage] = useState(3);
  const [pageCount, setPageCount] = useState(0);
  const [selectedPage, setselectedPage] = useState(1);
  const [totalOrg, setTotalOrg] = useState(0);
  const nextClickPaging = (e) => {
    setOffset(offset + perPage);
    setselectedPage(selectedPage + 1);
  };
  const lastClickPaging = (e) => {
    if (totalOrg % perPage === 0) {
      setOffset(totalOrg - perPage);
    } else {
      setOffset(totalOrg - (totalOrg % perPage));
    }
    setselectedPage(pageCount);
  };

  const preClickPaging = (e) => {
    setOffset(offset - perPage);
    setselectedPage(selectedPage - 1);
  };
  const firstClickPaging = (e) => {
    setOffset(0);
    setselectedPage(1);
  };
  // end const for paging

  const [modalShow, setModalShow] = React.useState(false);
  const [flagModalShow, setFlagModalShow] = useState("");
  const [orgModalShow, setOrgModalShow] = useState(false);
  const handleShowInforDetail = (event) => {
    setFlagModalShow(event.currentTarget.id);
    setModalShow(true);
  };
  const getOrganizations = (id) => {
    Axios.get( serverDomain+"/favorites/" + id).then((response) => {
      setActive(id);
      setUnitOrgData(response.data[0]);
      setOrgModalShow(true);
    });
  };

  const checkActive = (id) => {
    return id === active;
  };
  useEffect(() => {
    document.title = "Substance Use Plan";  
    Axios.get( serverDomain+"/login").then((response) => {
      if (response.data.loggedIn === true) {
        Axios.get( serverDomain+"/favorites/substanceUsePlanAll/").then((response) => {
          console.log("size " + response.data.length)
          setTotalOrg(response.data.length);
          setPageCount(Math.ceil(response.data.length / perPage));
        });
        Axios.post( serverDomain+"/favorites/substanceUsePlanPaging/", {
          offset: offset,
          perPage: perPage,
        }).then((response) => {
          setOrgData(response.data);
          console.log(response.data);
        });
      } else {
        history.push("/login");
      }
    });
  }, [offset]);
  useEffect(() => {
    Axios.post( serverDomain+"/login/insertLoginTrackerByIdAndPageName", {
      page_name: "substanceUsePlan",
    }).then(function(response) {
    })
    .catch(function(error) {
      console.log(error);
    })
  }, [])
  return (
    <div className="container home">
      <CommonNav />
      <div className="row justify-content-center">
        <div className="">
          <Card style={{ cursor: "pointer" }} onClick="">
            <Card.Body>
              <Card.Title className="cardTitleCustom">Substance Use Plan</Card.Title>
            </Card.Body>
          </Card>
        </div>
      </div>
        <div className="homeDisplay overflow-hidden">
        {totalOrg > 0 ? (
          <div className="disp1">
            <h5>Total Organizations: {totalOrg}</h5>
            {orgData.map(function(org) {
              return (
                <div
                  onClick={() => getOrganizations(org.org_id)}
                  className={classNames({
                    ldisp: true,
                    active: checkActive(org.org_id),
                  })}
                >
                  <div className="ttl">{org.name}</div>
                  <div className="subSecond">{org.address}</div>
                  <div className="subttl">{org.type}</div>
                </div>
              );
            })}
            <div className="pagingWrap">
              <Pagination>
                {selectedPage <= 1 ? (
                  <Pagination.First disabled />
                ) : (
                  <Pagination.First onClick={() => firstClickPaging()} />
                )}

                {selectedPage <= 1 ? (
                  <Pagination.Prev disabled />
                ) : (
                  <Pagination.Prev onClick={() => preClickPaging()} />
                )}
                <Pagination.Item>
                  page {selectedPage} of {pageCount}
                </Pagination.Item>

                {selectedPage >= pageCount ? (
                  <Pagination.Next disabled />
                ) : (
                  <Pagination.Next onClick={() => nextClickPaging()} />
                )}

                {selectedPage >= pageCount ? (
                  <Pagination.Last disabled />
                ) : (
                  <Pagination.Last onClick={() => lastClickPaging()} />
                )}
              </Pagination>
            </div>
          </div>
          ) : (
            <MessageNoFavorite/>
          )}
          <div className="disp2">
            <h3 className="textCenter">Finding Potential Substance Use Information</h3>
            <div className="row">
              <div className="col">
                <Card
                  id="enteringRecoveryPlan"
                  style={{ cursor: "pointer" }}
                  onClick={handleShowInforDetail}
                >
                  <Card.Body>
                    <Card.Title>Entering Recovery</Card.Title>
                    <Card.Text>
                    Getting to recovery can be incredibly challenging, but you can do it!
                    Here are some things you can do to stay on track with your goal of recovery...
                    </Card.Text>
                    <Button className="fullWidth" variant="primary">
                      See More
                    </Button>
                  </Card.Body>
                </Card>
              </div>
              <div className="col">
                <Card
                  id="findingHelp"
                  style={{ cursor: "pointer" }}
                  onClick={handleShowInforDetail}
                >
                  <Card.Body>
                    <Card.Title>Finding Help</Card.Title>
                    <Card.Text>
                    Dealing with a drug or alcohol problem is not easy. Many people find it hard to stop 
                    using substances without the help of others...
                    </Card.Text>
                    <Button className="fullWidth" variant="primary">
                     See More
                    </Button>
                  </Card.Body>
                </Card>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <Card
                  id="additionalResourcetoFindHelp"
                  style={{ cursor: "pointer" }}
                  onClick={handleShowInforDetail}
                >
                  <Card.Body>
                    <Card.Title>Additional Resources to Find Help</Card.Title>
                    <Card.Text>
                    There are several other resources that can help you work through your substance use 
                    problems. Take a closer look at the following resources and indicate which ones you 
                    went to learn more about...
                    </Card.Text>
                    <Button className="fullWidth" variant="primary">
                      See More
                    </Button>
                  </Card.Body>
                </Card>
              </div>
              <div className="col">
              </div>
            </div>

            <div className="row">
              {flagModalShow === "enteringRecoveryPlan"
                &&
                <EnteringRecovery
                show={modalShow}
                onHide={() => setModalShow(false)}
                />
              }

              {flagModalShow === "findingHelp"
                &&
                <FindingHelp
                show={modalShow}
                onHide={() => setModalShow(false)}
                />
              }

              {flagModalShow === "additionalResourcetoFindHelp"
                &&
                <AdditionalResourcesFindHelp
                show={modalShow}
                onHide={() => setModalShow(false)}
                />
              }
              {unit_org
                &&
                <OrganizationDetailPopup
                show={orgModalShow}
                onHide={() => setOrgModalShow(false)}
                unit_org ={unit_org}
                />
              }
            </div>
          </div>
        </div>
    </div>
  );
}

export default SubstanceUsePlan;
