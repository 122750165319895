import React, { } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Citation from "./Citation";

function VictimizingBehavior(props) {
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           <h3>Finding Potential Mental Health Services</h3> 
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <p><b><u>Victimizing Behavior</u></b></p>
          <p>Victimizing behavior does not stop because you were charged with a crime and either 
            placed on probation or incarcerated. You do not have to wait to change your thinking 
            and your behavior.</p>
          <p><b><u>If you continue any of the behaviors below, 
            you are continuing victimizing behavior:</u></b></p>
          <p>Lie.
              Con.
              Cheat.
              Steal.
              Damage property on purpose.
              Verbally abuse others.
              Physically abuse others.
              Sexually abuse others.
              Disrupt classes or group sessions.
              Intimidate others.
              Verbally threaten others in person, over the phone, or in writing.
              Set up another person.
              Blame others for what you did.
              Label a person in a negative way.
              Make a weaker person a target.
              Take advantage of others.
              Gossip about others.
              Start problems between others.
              Encourage someone to harm another person.
              Fail to be responsible for your behavior when another person is hurt.
            </p>
        </Modal.Body>
        <Modal.Footer>
          <Citation/>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default VictimizingBehavior;
